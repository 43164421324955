import { formatAsCurrency } from "utils/formatter-utils/currency-formatter";
import { useAppSelector } from "redux/hooks";
import { getGroupCurrencySymbol, getGroupState } from "redux/slices/group-slice";
import { useEffect, useState } from "react";
import { getRequest, postRequest } from "utils/httpClient";
import { useAuth } from "./auth";
import { getBalancePath, getWalletPath } from "utils/backend-path-builders";
import { APPROVED } from "utils/constants";
import { getIntegratedAppState } from "redux/slices/integrated-app-slice";
import store from "../redux/store";
import { postBalanceMessage } from "utils/integration/iframe-messages";

const useWallet = () => {
  const auth = useAuth();
  const [balance, setBalance] = useState(0);
  const [walletId, setWalletId] = useState("");
  const [loading, setLoading] = useState(false);
  const group = useAppSelector(getGroupState);
  const groupId = group.id;
  const groupStatus = group.status;
  const integratedAppState = useAppSelector(getIntegratedAppState);
  const state = store.getState();
  const groupSymbol = getGroupCurrencySymbol(state);
  const getWalletData = () => {
    if (integratedAppState.isIntegratedApp) {
      if (integratedAppState.userId) {
        setLoading(true);
        postRequest(getBalancePath())
          .then((response) => {
            postBalanceMessage(response.total);
            setBalance(response.total);
            setWalletId(response.wallet_id);
          })
          .catch((e) => console.log("failed to fetch balance", e))
          .finally(() => setLoading(false));
      }
    } else if (auth.signedIn && groupId !== "" && groupStatus === APPROVED) {
      setLoading(true);
      getRequest(getWalletPath(groupId))
        .then((data) => {
          setBalance(data.current_balance || 0);
          setWalletId(data.wallet_id);
          setLoading(false);
        })
        .catch(e => console.log("failed to fetch balance", e));
    }
  };

  useEffect(() => {
    getWalletData();
  }, [auth.signedIn, auth.user, groupId, groupStatus]);

  useEffect(() => {
    if (groupStatus === APPROVED) {
      setTimeout(getWalletData, 4000);
    }
  }, [groupStatus]);

  const formattedBalance = loading ? groupSymbol + " --.--" : formatAsCurrency(balance);

  return { balance, walletId, loading, formattedBalance };
};

export default useWallet;
