import OpenSansText from "components/custom-texts/open-sans-text";
import { LineBreak } from "./highlights";
import darkStyles from "../dark-styles.module.css";

import Accordion from "react-bootstrap/Accordion";
import AccordionHeader from "../accordion/accordion-header";
import { getGroupState } from "redux/slices/group-slice";
import { useAppSelector } from "redux/hooks";
import {
  FUNCTIONALITY_CUSTOMIZATION_MAPPING,
  PAGE_FUNCTIONALITY_CONTENT,
  PageFunctionalityContent
} from "./page-functionality-constants";
import { UICustomizations } from "../../../interfaces/ui-customizations";
import { filterContent } from "./filter-content";


const BodyContent = ({ content }: any) => {
  return (
    <>
      {content.map((paragraph: string, index: number) => {
        const isLast = index === content.length - 1;
        return (
          <div style={{ paddingLeft: 10, paddingRight: 10 }} key={index}>
            <OpenSansText>{paragraph}</OpenSansText>
            {!isLast && <LineBreak />}
          </div>
        );
      })}
    </>
  );
};

const PageFunctionality = ({ isActive }: any) => {

  const customization = useAppSelector(getGroupState).customization;
  const filteredContent = filterContent(customization, PAGE_FUNCTIONALITY_CONTENT, FUNCTIONALITY_CUSTOMIZATION_MAPPING);

  return (
    <>
      <Accordion>
        {filteredContent.map((page, index) => {
          return (
            <div key={index}>
              <AccordionHeader
                eventKey={index.toString()}
                styles={darkStyles}
                backgroundColor="var(--sparket-black)"
              >
                {page.title}
              </AccordionHeader>
              <Accordion.Collapse eventKey={index.toString()}>
                <BodyContent content={page.body} />
              </Accordion.Collapse>
            </div>
          );
        })}
      </Accordion>
    </>
  );
};

export default PageFunctionality;
