/**
 * @fileoverview AchievementsHeader component holds the header for the achievements section.
 */
import styles from "./styles.module.css";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import React from "react";


const BadgesHeader = () => {
  return (
    <div className={styles.text}>
      <BlockyBoldText>
        Badges
      </BlockyBoldText>
    </div>
  )
}

export default BadgesHeader;