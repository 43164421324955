import React, { useEffect, useRef } from "react";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { IntegratedAppSlice, setIntegratedState } from "redux/slices/integrated-app-slice";
import { getInitializeGamePath, getPrefixPath } from "utils/backend-path-builders";
import useQueryParams, { queryParamsToObject } from "utils/useQueryParams";
import { GROUP_SLUG, IS_INTEGRATED_APP } from "utils/constants";
import * as storage from "utils/local-storage";
import { getGroupState, setApprovedGroupStatus } from "redux/slices/group-slice";
import { useIsIntegratedAppHomePage } from "utils/integration/integrated-app-tools";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { getUserGroupSettingsByGroupId, UserGroupSettings } from "redux/slices/user-settings-slice";
import { addGroupChangeRequestMessageListener } from "utils/integration/iframe-messages";

const InitializeIntegrationApp = (groupSlug: any) => {

  const dispatch = useAppDispatch();
  const history = useHistory();
  const queryParams = useQueryParams();
  const group = useAppSelector(getGroupState);
  const userGroupSettings = useAppSelector(state => getUserGroupSettingsByGroupId(state, group.id));

  const groupSettingsRef: React.MutableRefObject<{
    userGroupSettings: UserGroupSettings | undefined
  }> = useRef({ userGroupSettings });

  useEffect(() => {
    groupSettingsRef.current = { userGroupSettings };
  }, [userGroupSettings]);

  const isIntegratedAppHomePage = useIsIntegratedAppHomePage();

  if (groupSlug) {
    storage.local.setItem(GROUP_SLUG, groupSlug);
  }

  if (isIntegratedAppHomePage) {
    storage.local.setItem(IS_INTEGRATED_APP, "true");
  }

  const takeUserToTheIntegratedAppInitialPage = () => {
    const initialPage = queryParams.get("initialPage");
    if (initialPage) {
      history.push(initialPage);
    }
  };

  const sendInitializeGameRequest = async (externalLoginToken: string, externalUserId: string | null) => {
    const queryParamsJson = queryParamsToObject(queryParams);

    const res = await fetch(
      process.env.REACT_APP_BACKEND_URL +
      getPrefixPath() + "/" +
      groupSlug +
      getInitializeGamePath(),
      {
        method: "POST",
        headers: {
          "x-user-id": externalUserId || "",
          "x-login-token": externalLoginToken,
        },
        body: JSON.stringify(queryParamsJson),
      }
    );

    const json = await res.json();

    dispatch(
      setIntegratedState({
        isIntegratedApp: true,
        userId: json.external_user_id,
        token: json.token,
      } as IntegratedAppSlice)
    );
    dispatch(setApprovedGroupStatus());
  };

  useEffect(() => {
    if (!isIntegratedAppHomePage) return;

    dispatch(setIntegratedState({ isIntegratedApp: true } as IntegratedAppSlice));

    const externalUserId = queryParams.get("user_id");
    const externalLoginToken = queryParams.get("login_token");

    if (externalLoginToken) {
      sendInitializeGameRequest(externalLoginToken, externalUserId)
        .then(() => takeUserToTheIntegratedAppInitialPage())
        .catch(e => {
          toast.error(`Something went wrong.`);
          console.error("failed to initialize a game", e);
        });
    } else {
      takeUserToTheIntegratedAppInitialPage();
    }
  }, [isIntegratedAppHomePage]);

  useEffect(() => {
    if (!isIntegratedAppHomePage) return;
    addGroupChangeRequestMessageListener(groupSettingsRef);
  }, [isIntegratedAppHomePage]);

};

export default InitializeIntegrationApp;
