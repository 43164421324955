import { Alert, Button, Modal, Form } from "react-bootstrap";
import sharedModalStyles from "assets/shared-stylesheets/modal.module.css";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getGroupState, isRealMoneyGroup } from "redux/slices/group-slice";
import OpenSansText from "components/custom-texts/open-sans-text";
import {
  openContestTerms,
  openPrivacyPolicy,
} from "redux/slices/legal-modal-slice";
import BlockyMediumText from "components/custom-texts/blocky-medium-text";
import { useHistory } from "react-router-dom";
import { getVerificationStatus } from "redux/slices/user-slice";
import { IDENTITY_VERIFICATION_STATUS } from "interfaces/users-response";
import { ID_VERIFICATION_REQUEST_PATH, secondaryColor, textColor } from "utils/constants";
import { useEffect, useState } from "react";

interface Props {
  show: boolean;
  close: () => void;
  acceptAndJoin: () => void;
}

const JoinGroupModal = ({ show, close, acceptAndJoin }: Props) => {
  const group = useAppSelector(getGroupState);
  const primaryColor = group.settings.primary_color;
  const dispatch = useAppDispatch();
  const contestLabel = group.settings.contest_label;
  const [minAgeChecked, setMinAgeChecked] = useState(false);
  const realMoneyGroup = useAppSelector(isRealMoneyGroup);
  const identityVerified =
    useAppSelector(getVerificationStatus) ===
    IDENTITY_VERIFICATION_STATUS.APPROVED;
  const showIdentityVerificationBanner = realMoneyGroup && !identityVerified;
  const history = useHistory();

  useEffect(() => {
    setMinAgeChecked(false);
  }, [show]);

  const onAccept = () => {
    acceptAndJoin();
    if (showIdentityVerificationBanner) {
      history.push(ID_VERIFICATION_REQUEST_PATH);
    }
  };

  return (
    <Modal show={show} dialogClassName="modal-90w" onHide={close}>
      <Modal.Title
        style={{
          justifyContent: "space-between",
          backgroundColor: primaryColor,
        }}
        className={sharedModalStyles.modalTitle}
      >
        <div />
        <BlockyHeavyText style={{ color: textColor }}>{group.name} Terms</BlockyHeavyText>
        <Button
          style={{
            backgroundColor: primaryColor,
            border: "none",
          }}
          onClick={close}
        >
          <BlockyHeavyText customStyles={{ color: textColor }}>X</BlockyHeavyText>
        </Button>
      </Modal.Title>
      <Modal.Body
        className={sharedModalStyles.modalBody}
        style={{ borderColor: primaryColor }}
      >
        <p>
          <i>You need to join {group.name} to make a pick</i>
        </p>
        <ul
          style={{
            fontSize: 12,
            height: 110,
            display: "flex",
            justifyContent: "space-around",
            flexDirection: "column",
          }}
        >
          <li>
            <OpenSansText>
              Your profile information and pick data may be shared with{" "}
              {group.settings.group_owner} per our{" "}
              <a onClick={() => dispatch(openPrivacyPolicy())} href="#">
                Privacy Policy
              </a>
            </OpenSansText>
          </li>
        </ul>
        <Form.Check
          type="checkbox"
          id="min-age-checkmark"
          label={`I am ${group.min_age} years of age or older`}
          onChange={(event) => {
            setMinAgeChecked(event.target.checked);
          }}
        />
        <div style={{ display: "flex", marginBottom: 20 }}>
          <Button
            style={{
              backgroundColor: "white",
              marginTop: 20,
              borderColor: secondaryColor,
              color: "black",
            }}
            onClick={close}
          >
            <BlockyMediumText>Decline</BlockyMediumText>
          </Button>
          <div style={{ width: 10 }} />
          <Button
            style={{
              backgroundColor: secondaryColor,
              border: "none",
              marginTop: 20,
              color: "black",
            }}
            onClick={onAccept}
            disabled={!minAgeChecked}
          >
            <BlockyHeavyText style={{ color: "black" }}>Accept</BlockyHeavyText>
          </Button>
        </div>
        {showIdentityVerificationBanner && (
          <Alert variant="warning">
            You must verify your identity to join this group. Clicking 'Accept'
            will kick off the verification process.
          </Alert>
        )}
      </Modal.Body>
    </Modal>
  );
};

export default JoinGroupModal;
