import Headroom from "react-headroom";
import Header from "components/header";
import Leaderboard from "components/leaderboard";
import React from "react";

const LeaderboardPage = () => {
  return (
    <>
      <Headroom>
        <Header />
      </Headroom>
      <Leaderboard />
    </>
  );
};

export default LeaderboardPage;
