import Highlights from "./content/highlights";
import LiveMarkets from "./content/live-markets";
import PageFunctionality from "./content/page-functionality";
import Terminology from "./content/terminology";
import ResponsibleGaming from "./content/responsible-gaming";
import SupportedBrowsers from "./content/supported-browsers";
import TitleBar from "./title-bar";
import Header from "components/header";
import topLevelStyles from "./top-level-styles.module.css";
import Accordion from "react-bootstrap/Accordion";
import AccordionHeader from "./accordion/accordion-header";
import { useAppSelector } from "redux/hooks";
import { getGroupStyles, getGroupState } from "redux/slices/group-slice";
import OpenSansText from "components/custom-texts/open-sans-text";
import LoginButtons from "components/login-buttons";
import SellBet from "./content/sell-bet";
import { textColor } from "utils/constants"

const SECTIONS = [
  { title: "Highlights", content: Highlights },
  { title: "Page Functionality", content: PageFunctionality },
  { title: "Terminology", content: Terminology },
  // { title: "Responsible Gaming", content: ResponsibleGaming },
  { title: "Supported Browsers", content: SupportedBrowsers },
  // { title: "Live Markets", content: LiveMarkets },
  // { title: "Sell Bet", content: SellBet },
  {
    title: "Contact",
    content: () => (
      <div>
        <div style={{ paddingLeft: 10, paddingRight: 10 }}>
          <OpenSansText>
            Questions? Contact us at support@betsparket.com
          </OpenSansText>
        </div>
      </div>
    ),
  },
];

const Help = () => {
  const primaryColor = useAppSelector(getGroupStyles).primary_color;
  const includeContact = useAppSelector(getGroupState).customization.contact;

  const filteredSections = includeContact
    ? SECTIONS
    : SECTIONS.filter((section) => section.title !== "Contact");

  return (
    <>
      <Header />
      <LoginButtons />
      <TitleBar />
      <Accordion>
        {filteredSections.map((section, index) => {
          const Content = section.content;
          return (
            <div key={index}>
              <AccordionHeader
                eventKey={index.toString()}
                backgroundColor={primaryColor}
                styles={topLevelStyles}
              >
                {section.title}
              </AccordionHeader>
              <Accordion.Collapse eventKey={index.toString()}>
                <Content />
              </Accordion.Collapse>
            </div>
          );
        })}
      </Accordion>
    </>
  );
};

export default Help;
