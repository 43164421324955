import { Dispatch, SetStateAction, useState, useEffect } from "react";
import OpenSansText from "components/custom-texts/open-sans-text";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { Button, Form, InputGroup } from "react-bootstrap";
import styles from "./styles.module.css";
import { toast } from "react-toastify";
import { Mixpanel } from "hooks/mixPanel";
import { showSignIn } from "redux/slices/login-modals-slice";
import LegalDisclaimer from "./legal-disclaimer";
import useVerificationCode from "components/sign-in/use-login-data";
import SixDigitCodeInput from "components/six-digit-code-input";
import { useAppSelector } from "redux/hooks";
import { getGroupState } from "redux/slices/group-slice";
import GoogleGLogo from "icons/google-G-logo.svg";
import FacebookLogo from "icons/facebook-logo.svg";
import { secondaryColor } from "utils/constants";

export interface LoginContentProps {
  setAllowClickOutside: Dispatch<SetStateAction<boolean>>;
  setShowConfirmationForm: Dispatch<SetStateAction<boolean>>;
  showConfirmationForm: boolean;
}

const SignUp = ({
  setAllowClickOutside,
  showConfirmationForm,
  setShowConfirmationForm,
}: LoginContentProps) => {
  const [passwordError, setPasswordError] = useState("");

  const [showEmailPassword, setShowEmailPassword] = useState(false);
  const [passwordType, setPasswordType] = useState("password");

  useEffect(() => {
    if (showConfirmationForm) {
      setAllowClickOutside(false);
    } else {
      setAllowClickOutside(true);
    }
  }, [showConfirmationForm]);

  const group = useAppSelector(getGroupState);
  const iframe = group.customization.iframe;
  const groupId = group.id;

  const {
    code,
    setCode,
    wrongCode,
    setWrongCode,
    loading,
    email,
    onChangeEmail,
    password,
    onChangePassword,
    auth,
    dispatch,
  } = useVerificationCode();

  if (showConfirmationForm) {
    return (
      <div
        style={{
          color: "white",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "space-evenly",
          paddingBottom: 40,
          height: 150,
        }}
      >
        <OpenSansText customStyles={{ textAlign: "center" }}>
          We sent a verification code to {email}.
        </OpenSansText>
        <OpenSansText>Please enter the code below.</OpenSansText>

        <SixDigitCodeInput
          {...{ code, wrongCode, setWrongCode, setCode, loading }}
        />
      </div>
    );
  } else {
    return (
      <Form style={{ color: "white" }} className={styles.form}>
        {/* google */}
        {!iframe && (
          <>
            <Button
              className={styles.signUpWithGoogleButton}
              onClick={() => {
                Mixpanel.track("Clicked Sign Up with Google", {
                  isSignedIn: auth.signedIn,
                });
                auth.googleSignIn(groupId).catch((err) => {
                  Mixpanel.track("Error Signing in with Google", {
                    isSignedIn: auth.signedIn,
                  });
                  toast.error("Error signing in with Google");
                });
              }}
            >
              <img width="20px" alt="Google sign-in" src={GoogleGLogo} />
              <OpenSansText className={styles.googleText}>
                Sign up with Google
              </OpenSansText>
            </Button>
            <Button
              style={{
                marginBottom: "2vh",
                width: "100%",
                backgroundColor: "#4267b2",
                textAlign: "left",
              }}
              onClick={() => {
                Mixpanel.track("Clicked Sign Up with Facebook", {
                  isSignedIn: auth.signedIn,
                });
                auth.facebookSignIn(groupId).catch((err) => {
                  toast.error("Error signing up with Facebook");
                });
              }}
            >
              <img width="20px" alt="Facebook sign-in" src={FacebookLogo} />
              {/* facebook */}
              <OpenSansText className={styles.facebookText}>
                Sign up with Facebook
              </OpenSansText>
            </Button>
            <OpenSansText style={{ color: "#FFFFFF", marginBottom: "1vh" }}>
              - OR -
            </OpenSansText>
          </>
        )}
        {!showEmailPassword ? (
          <>
            <Button
              style={{
                marginBottom: "2vh",
                width: "100%",
                backgroundColor: "#FFFFFF",
                textAlign: "left",
              }}
              onClick={() => setShowEmailPassword(true)}
            >
              <img
                width="20px"
                alt="email-icon"
                src="https://img.icons8.com/ios/50/000000/email-open.png"
              />
              <OpenSansText className={styles.googleText}>
                Sign up with Email
              </OpenSansText>
            </Button>
          </>
        ) : (
          <>
            <Form.Group style={{ width: "inherit" }}>
              <Form.Control
                type="email"
                placeholder="Email"
                onChange={(e) => onChangeEmail(e.target.value)}
              />
            </Form.Group>
            <Form.Group style={{ width: "inherit" }}>
              <InputGroup hasValidation>
                <Form.Control
                  type={passwordType}
                  placeholder="Password"
                  isInvalid={passwordError !== ""}
                  onChange={(e) => {
                    setPasswordError("");
                    onChangePassword(e.target.value);
                  }}
                />
                <InputGroup.Text id="inputGroupAppend">
                  <span
                    className="flaticon-eye"
                    // toggle password type to show/hide
                    onClick={() => {
                      passwordType === "password"
                        ? setPasswordType("text")
                        : setPasswordType("password");
                    }}
                  >
                    <img
                      width="18px"
                      alt="show-password-icon"
                      src="https://img.icons8.com/material-rounded/24/000000/visible.png"
                    />
                  </span>
                </InputGroup.Text>
                <Form.Control.Feedback type="invalid">
                  {passwordError}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Button
              style={{
                marginBottom: "1.5vh",
                width: "100%",
                backgroundColor: secondaryColor,
                color: "black",
              }}
              type="submit"
              onClick={(e) => {
                Mixpanel.track("Clicked Sign Up with Username and Password", {
                  isSignedIn: auth.signedIn,
                });
                e.preventDefault();
                if (password.length < 8) {
                  setPasswordError("Password must be at least 8 characters");
                } else {
                  auth.amplifyAuth
                    .signUp({ username: email, password: password })
                    .then((res) => {
                      setShowConfirmationForm(true);
                    })
                    .catch((err) => {
                      toast.error(err.message);
                    });
                }
              }}
            >
              <BlockyBoldText className={styles.signedInBtnText}>
                CREATE ACCOUNT
              </BlockyBoldText>
            </Button>
          </>
        )}

        <LegalDisclaimer />
        <BlockyBoldText>Already have an account?</BlockyBoldText>
        <a
          href="#"
          style={{ width: "100%", color: secondaryColor, textAlign: "center" }}
          onClick={() => dispatch(showSignIn())}
        >
          <BlockyBoldText>Sign In</BlockyBoldText>
        </a>
      </Form>
    );
  }
};

export default SignUp;
