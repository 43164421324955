// v2/groups
const GROUPS_LAMBDA_PREFIX = "/v2/groups";
export const getPoolDetailsPath = (groupId: string, poolId: string) =>
  `${GROUPS_LAMBDA_PREFIX}/group/${groupId}}/pools/pool/${poolId}`;

export const getGroupDetailsPath = (groupId: string) =>
  `${GROUPS_LAMBDA_PREFIX}/group/${groupId}`;

export const getGroupBySlugPath = (slug: string) =>
  `${GROUPS_LAMBDA_PREFIX}/group/slug/${slug}`;

export const getPoolsPath = (groupId: string) =>
  `${GROUPS_LAMBDA_PREFIX}/group/${groupId}/pools`;

export const getBetDetailsPath = (groupId: string, betId: string) =>
  `${GROUPS_LAMBDA_PREFIX}/group/${groupId}/bets/bet/${betId}`;

export const getMyBetsPath = (groupId: string) =>
  `${GROUPS_LAMBDA_PREFIX}/group/${groupId}/bets`;

export const getContestsPath = (groupId: string) =>
  `${GROUPS_LAMBDA_PREFIX}/group/${groupId}/contests`;

export const getSocialShareLinksPath = (poolID: string) =>
  `/v2/pools/pool/${poolID}/socialshare`;

export const getConfirmBetPath = (
  groupId: string,
  poolId: string,
  poolEntryId: string
) =>
  `${GROUPS_LAMBDA_PREFIX}/group/${groupId}/pools/pool/${poolId}/entries/entry/${poolEntryId}/bet`;

export const getJoinGroupPath = (groupId: string) =>
  `${GROUPS_LAMBDA_PREFIX}/join/${groupId}`;

export const getPrizeoutSettingsPath = (groupId: string) =>
  `${GROUPS_LAMBDA_PREFIX}/group/${groupId}/prizeout-settings`;

export const getEntryPath = (groupId: string, poolId: string) =>
  `${GROUPS_LAMBDA_PREFIX}/group/${groupId}/pools/pool/${poolId}/entries/entry`;
// v2/pmSys
const PMSYS_LAMBDA_PREFIX = "/v2/pmSys";
export const getConfirmedContestantPath = (poolId: string) =>
  `${PMSYS_LAMBDA_PREFIX}/confirmation/pool/${poolId}/selected`;

export const getConfirmContestantPath = () =>
  `${PMSYS_LAMBDA_PREFIX}/confirmation/record`;

// v2/wallet
const WALLET_LAMBDA_PREFIX = "/v2/wallet";
export const getWalletPath = (groupId: string) =>
  `${WALLET_LAMBDA_PREFIX}/user/group/${groupId}`;

// v2/transactions
const TRANSACTIONS_LAMBDA_PREFIX = "/v2/transactions";
export const getTransactionsForWalletPath = (walletId: string) =>
  `${TRANSACTIONS_LAMBDA_PREFIX}/wallet/${walletId}`;

export const getLeaderboardDataPath = () =>
  `${TRANSACTIONS_LAMBDA_PREFIX}/leader-board`;

// v2/users
const USERS_LAMBDA_PREFIX = "/v2/users";
export const getUserPath = () => `${USERS_LAMBDA_PREFIX}/me`;
export const getUserPathNotifications = () =>
  `${USERS_LAMBDA_PREFIX}/me?notifications=1`;

export const getUsersPersonalInformationPath = () =>
  `${USERS_LAMBDA_PREFIX}/verification/personal-information`;

export const getUsersExternalIdPath = () =>
  `${USERS_LAMBDA_PREFIX}/external-id`;

export const getUsersExternalIdsPath = () =>
  `${USERS_LAMBDA_PREFIX}/external-ids`;

export const getSubmitAvaterPath = () =>
  `${USERS_LAMBDA_PREFIX}/profile/avatar`;

export const getValidatePotentialUsernamePath = () =>
  `${USERS_LAMBDA_PREFIX}/onboarding/username`;

export const getVerificationRequestPath = (userId: string) =>
  `${USERS_LAMBDA_PREFIX}/user/${userId}/verification/idscan/sms`;

export const getFailedLoginPath = () =>
  `${USERS_LAMBDA_PREFIX}/user/events/event/login-failed`;

export const getAvailableGroupsPath = () =>
  `${USERS_LAMBDA_PREFIX}/groups/list`;

export const getSkillScorePath = (userId: string) =>
  `${USERS_LAMBDA_PREFIX}/stats/${userId}`;

// v2/notification-configurations
const CONFIGURABLE_NOTIFICACTIONS_LAMBDA_PREFIX =
  "/v2/notification-configurations";
export const getConfigurableNotificationsPath = () =>
  CONFIGURABLE_NOTIFICACTIONS_LAMBDA_PREFIX;

// v2/legal
const LEGAL_LAMBDA_PREFIX = "/v2/legal";
export const getPrivacyPolicyPath = () =>
  `${LEGAL_LAMBDA_PREFIX}/docs/privacy-policy/v1`;

export const getTermsOfServicePath = () =>
  `${LEGAL_LAMBDA_PREFIX}/docs/terms-of-service/v1`;

const EVENTS_LAMBDA_PREFIX = "/v2/events";
export const getEventsPath = () => EVENTS_LAMBDA_PREFIX;

// v2/limitations
const LIMITATIONS_LAMBDA_PREFIX = "/v2/limitations";
export const getSelfLimitationsPath = () => `${LIMITATIONS_LAMBDA_PREFIX}/self`;
export const getSelfExclusionPath = () =>
  `${LIMITATIONS_LAMBDA_PREFIX}/self/exclusion`;

// INTEGRATION PATHS

export const getPrefixPath = () => "/integrations";

// initialize game path
export const getInitializeGamePath = () => "/initialize-game";

export const getBalancePath = () => "/balance";

export const integratedCreateBet = (poolId: string, entryId: string) =>
  `/${poolId}/entries/entry/${entryId}/bet`;

export const integratedMyBets = () => "/bets";

export const integratedGetBetDetails = (betId: string) => `/bets/bet/${betId}`;

export const integratedUserNotifications = () => "/users/me";

const PASS_PREFIX = "/pass"

export const getRewardsPath = () => `/pass/user/rewards`;
export const createPassUserLevelForGroup = (groupId: string) => `${PASS_PREFIX}/group/${groupId}/create`