import Home from "home/home";
import { Route, Switch, useLocation } from "react-router-dom";
import PoolDetails from "components/pool-details";
import MyBets from "components/my-bets";
import Help from "components/help";
import Bankroll from "components/bankroll";
import Profile from "components/profile/profile";
import ProfileEdit from "components/profile/profile-edit/profileEdit";
import Drawer from "components/drawer";
import "rsuite/dist/styles/rsuite-default.css";
import useDeviceDimensions from "hooks/useDeviceDimensions";
import SideBar from "components/sidebar";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { close } from "redux/slices/drawer-slice";
import ForgotPassword from "components/forgot-password";
import SparketTutorial from "components/sparket-tutorial";
import LeaderboardPage from "components/leaderboard-page";
import LegalPage from "components/legal-page";
import ConfirmResults from "components/confirm-results";
import ConfirmResultsPoolDetails from "components/confirm-results/pool-details";
import {
  fetchGroupCustomization,
  getGroupSecondaryColorOrDefault,
  getGroupState,
  getGroupStyles,
  setLoading
} from "redux/slices/group-slice";
import ReactGA4 from "react-ga4";
import React, { useEffect } from "react";
import InitializeGroupState from "app-setup/initialize-group-state";
import InitializeGeolocation from "app-setup/initialize-geolocation";
import InitializeUserState from "app-setup/initialize-user-state";
import CheckGroupStatus from "app-setup/check-group-status";
import LegalModal from "components/legal-modal";
import ExternalIdModal from "components/external-id-form";
import LoginModal from "components/profile/login-modal";
import TwoFactorModal from "components/profile/two-factor-auth";
import IdVerificationRequest from "components/id-verification-request";
import { getPrivacyPolicyPath, getTermsOfServicePath, } from "utils/backend-path-builders";
import { toast } from "react-toastify";
import ResponsibleGaming from "./components/responsible-gaming";
import UnsupportedBrowserAlert from "components/unsupported-browser-alert";
import OfflineAlert from "components/offline-alert";
import { getUserState } from "redux/slices/user-slice";
import { APPROVED, ID_VERIFICATION_REDIRECT_PATH, ID_VERIFICATION_REQUEST_PATH, } from "utils/constants";
import { WagerWireIframe, WagerWireProvider } from "@wagerwire/embed/react";
import { useAuth } from "hooks/auth";
import ContestPopup from "components/contest-popup/modal";
import { getIntegratedAppState, removeIntegratedState } from "redux/slices/integrated-app-slice";
import NotificationSettings from "components/notification-settings";
import {
  APP_CONFIG_OVERRIDES_LOCAL_STORAGE_KEY,
  checkIfAppConfigResetIsRequired,
  defaultAppConfig,
  getAppConfigOverridesFromLocalStorage,
  getAppConfigOverridesFromQueryParams,
  setAppConfig
} from "redux/slices/app-config-slice";
import { INTEGRATED_APP_HOME_PAGE_URL_PREFIX } from "utils/integration/constants";
import { useIsIntegratedAppHomePage } from "utils/integration/integrated-app-tools";
import { RootState } from "redux/store";
import { useSelector } from "react-redux";

ReactGA4.initialize("G-W4C48RRRY7");

const App = () => {
  const excludeNavigationPaths = [
    "/forgot-password",
    "/privacy-policy",
    "/terms-of-service",
  ];

  const excludeBackgroundColorPaths = [
    "/forgot-password",
    "/privacy-policy",
    "/terms-of-service",
    "/help",
    "/edit-profile",
    "/profile",
    "/bankroll",
    "/leaderboard",
    ID_VERIFICATION_REQUEST_PATH,
    "/responsible-gaming",
    "/notification-settings",
  ];

  const appState = useSelector((state: RootState) => state);
  const group = useAppSelector(getGroupState);
  const location = useLocation();
  const groupHasLoaded = group.id !== "";
  const showNavigation = !excludeNavigationPaths.includes(location.pathname);
  const showBackgroundColor =
    !excludeBackgroundColorPaths.includes(location.pathname) && groupHasLoaded;

  useEffect(() => {
    if (location.pathname === ID_VERIFICATION_REDIRECT_PATH) {
      toast.info(
        "ID Verification in Progress. Please check your profile image for a green check to confirm completion."
      );
    }
  }, []);

  const userId = useAppSelector(getUserState).id;

  if (group.settings.enable_geolocation && userId) {
    InitializeGeolocation(userId);
  }

  // TODO once we move away from storing is integrated app var in local storage we'll be able to remove it
  const embeddedInIframe = window !== window.top;
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!embeddedInIframe) {
      dispatch(removeIntegratedState());
    }
  }, [embeddedInIframe]);

  const isIntegratedAppHomePage = useIsIntegratedAppHomePage();

  // need this condition after leaving home page
  const isIntegratedApp = useAppSelector(getIntegratedAppState).isIntegratedApp;

  if (!isIntegratedApp && !isIntegratedAppHomePage) {
    InitializeGroupState(location.pathname);
    InitializeUserState();
  }

  CheckGroupStatus();

  useEffect(() => {
    ReactGA4.send({
      hitType: "pageview",
      page: location.pathname,
      title: "Page View",
    });
  }, [location]);

  const wagerWireEnabled = !!group.settings.wager_wire;
  const { isMobile } = useDeviceDimensions();

  useEffect(() => {
    dispatch(close());
  }, [isMobile]);


  useEffect(() => {
    if (!isIntegratedApp && !isIntegratedAppHomePage && groupHasLoaded) {
      dispatch(fetchGroupCustomization(group.id));
    }
  }, [groupHasLoaded])

  const handleAppConfigChange = () => {
    if (checkIfAppConfigResetIsRequired()) {
      console.log("resetting app config to defaults", defaultAppConfig);
      dispatch(setAppConfig(defaultAppConfig));
      localStorage.removeItem(APP_CONFIG_OVERRIDES_LOCAL_STORAGE_KEY);
    } else {
      const appConfigOverrides = getAppConfigOverridesFromQueryParams();
      if (Object.keys(appConfigOverrides).length > 0) {
        console.log("applying app config overrides", appConfigOverrides);

        dispatch(setAppConfig(appConfigOverrides));

        const storedOverrides = getAppConfigOverridesFromLocalStorage();
        localStorage.setItem(
          APP_CONFIG_OVERRIDES_LOCAL_STORAGE_KEY,
          JSON.stringify({
            ...storedOverrides,
            ...appConfigOverrides,
          })
        );
      }
    }
  };

  useEffect(() => handleAppConfigChange(), [location]);

  const conditionalStyles = isMobile
    ? {}
    : {
      alignSelf: "flex-end",
      height: "100vh",
      overflow: "scroll",
      width: "100%",
    };

  const primaryColor = useAppSelector(getGroupStyles).primary_color;

  const backgoundStyles = showBackgroundColor
    ? {
      backgroundImage: `linear-gradient(to right, ${primaryColor} , black)`,
    }
    : { backgroundColor: "#F1F2F2" };

  const auth = useAuth();
  const showLogin = group.customization.sign_in_up;
  const showBankroll = group.customization.bankroll_page;
  const showLeaderboard = group.customization.leaderboard;

  const secondaryColor = useAppSelector(getGroupSecondaryColorOrDefault);
  const root = document.documentElement;
  root.style.setProperty("--secondary-color", secondaryColor);

  const isBlackText = group.customization.black_text;
  root.style.setProperty("--text-color", isBlackText ? "black" : "white");

  return (
    <WagerWireProvider
      userId={auth.signedIn && group.status === APPROVED ? userId : ""}
      embedOrigin={process.env.REACT_APP_WAGER_WIRE_EMBED_ORIGIN || ""}
    >
      <div style={{ display: isMobile ? "initial" : "flex" }}>
        {showNavigation && <>{isMobile ? <Drawer /> : <SideBar />}</>}
        <div
          style={{
            minHeight: "100vh",
            ...backgoundStyles,
            ...conditionalStyles,
          }}
        >
          <Switch>
            <Route exact path="/profile" component={Profile} />
            <Route exact path="/edit-profile" component={ProfileEdit} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route
              exact
              path={ID_VERIFICATION_REQUEST_PATH}
              component={IdVerificationRequest}
            />
            <Route exact path="/mypicks" component={MyBets} />
            <Route exact path="/bets/:id" component={MyBets} />
            <Route exact path="/pools/:id" component={PoolDetails} />
            <Route exact path="/help" component={Help} />
            {showBankroll && (
              <Route exact path="/bankroll" component={Bankroll} />
            )}
            {showLeaderboard && (
              <Route exact path="/leaderboard" component={LeaderboardPage} />
            )}
            <Route exact path="/privacy-policy">
              <LegalPage url={getPrivacyPolicyPath()} />
            </Route>
            <Route exact path="/terms-of-service">
              <LegalPage url={getTermsOfServicePath()} />
            </Route>
            <Route
              exact
              path="/notification-settings"
              component={NotificationSettings}
            />
            <Route exact path="/confirm-results" component={ConfirmResults} />
            <Route
              exact
              path="/pools/:id/confirm"
              component={ConfirmResultsPoolDetails}
            />
            <Route
              exact
              path="/responsible-gaming"
              component={ResponsibleGaming}
            ></Route>
            <Route exact path="/" component={Home} />
            <Route
              exact
              path={ID_VERIFICATION_REDIRECT_PATH}
              component={Home}
            />
            <Route exact path="/g/:groupSlug" component={Home} />
            <Route exact path={`/${INTEGRATED_APP_HOME_PAGE_URL_PREFIX}/:groupSlug`} component={Home} />
          </Switch>
          <SparketTutorial />
          <LegalModal />
          <ExternalIdModal />
          {showLogin && <LoginModal />}
          <TwoFactorModal />
          {groupHasLoaded && !group.customization.iframe && (
            <UnsupportedBrowserAlert />
          )}
          <OfflineAlert />
          <ContestPopup />
        </div>
      </div>
      {wagerWireEnabled && <WagerWireIframe />}
    </WagerWireProvider>
  );
};

export default App;
