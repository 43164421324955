import { getGroupBySlugPath, getPoolDetailsPath, getPoolsPath } from "utils/backend-path-builders";
import { getRequest } from "utils/httpClient";
import { POOL_STATUS_PARAM } from "utils/constants";
import { Pool } from "interfaces/pool";
import { EVENT_ID_QUERY_PARAM } from "home/home-page-query-parameters";
import { UserGroupSettings } from "redux/slices/user-settings-slice";

const GROUP_CHANGE_REQUEST = `GROUP_CHANGE_REQUEST`;
const GROUP_CHANGE_RESPONSE = "GROUP_CHANGE_RESPONSE";

export interface IframeMessage {
  type: string;
  payload: object;
}

const postMessageToParentWindow = (message: IframeMessage) => {
  window.parent.postMessage(message, "*");
};

export const postBalanceMessage = (balance: number) => {
  postMessageToParentWindow({
    type: "balance",
    payload: {
      amount: balance
    }
  });
};

export const postAttemptToPlaceABetMessage = (poolId: string) => {
  postMessageToParentWindow({
    type: "user-action",
    payload: {
      actionType: "attempt-to-place-a-bet",
      poolId: poolId
    }
  });
};

export let groupChangeRequestListener: any | undefined;

const extractPoolId = (pathname: string): string | null => {
  const match = pathname.match(/\/pools\/([0-9a-fA-F-]{36})/);
  return match ? match[1] : null;
};

const handleCurrencyChangeRequest = async (event: MessageEvent, userGroupSettings: UserGroupSettings | undefined) => {
  let correspondingPage = "/";

  const poolId = extractPoolId(window.location.pathname);

  if (poolId) {
    const { groupSlug } = event.data.payload;

    const groupPath = getGroupBySlugPath(groupSlug);
    const groupResponse = await getRequest(groupPath, { skipIntegrationApi: true });
    if (!groupResponse) {
      console.error(`unable to process CHANGE_GROUP_REQUEST - no group found by ${groupSlug}`);
      return;
    }

    const changeToGroupId = groupResponse.id;

    const pools = await getRequest(getPoolsPath(changeToGroupId), {
      queryParams: {
        status: POOL_STATUS_PARAM.OPEN,
        pg: 1,
        page_size: 10000,
        sortBy: "weight:desc,close:asc"
      },
      skipIntegrationApi: true
    });

    const currentPool = await getRequest(
      getPoolDetailsPath(changeToGroupId, poolId),
      { skipIntegrationApi: true }
    );

    const correspondingPool = pools.find(
      (p: Pool) =>
        p.name === currentPool.name &&
        p.start === currentPool.start &&
        p.close === currentPool.close &&
        p.event_id === currentPool.event_id
    );

    if (correspondingPool) {
      correspondingPage = `/pools/${correspondingPool.id}`;
    }
  } else if (window.location.pathname === "/") {
    const homePagePoolsEventIdFilter = userGroupSettings?.homePagePoolsEventIdFilter;
    if (homePagePoolsEventIdFilter) {
      correspondingPage = `/?${EVENT_ID_QUERY_PARAM}=${homePagePoolsEventIdFilter}`;
    } else {
      correspondingPage = `/?${EVENT_ID_QUERY_PARAM}=`;
    }
  } else {
    correspondingPage = window.location.pathname;
  }

  const responseMessage = {
    type: GROUP_CHANGE_RESPONSE,
    payload: {
      correspondingPage: correspondingPage
    }
  };

  window.parent.postMessage(responseMessage, event.origin);
};

export const addGroupChangeRequestMessageListener = (userGroupSettings: React.MutableRefObject<{
  userGroupSettings: UserGroupSettings | undefined
}>) => {
  if (!groupChangeRequestListener) {
    groupChangeRequestListener = (event: MessageEvent) => {
      const { type } = event.data;

      if (type === GROUP_CHANGE_REQUEST) {
        handleCurrencyChangeRequest(event, userGroupSettings.current.userGroupSettings)
          .catch(e => console.error(`unable to process ${GROUP_CHANGE_REQUEST}`, e));
      }
    };

    window.addEventListener("message", groupChangeRequestListener);
  }
};