import Dinero from "dinero.js";
import store from "redux/store";
import { DOLLAR_SIGN, getGroupCurrencySymbol } from "redux/slices/group-slice";
import { SPORTZINO_SYMBOLS } from "utils/constants";

export const formatAsCurrency = (amount: number, useDollars?: boolean) => {
  const state = store.getState();
  let currencySymbol;
  currencySymbol = getGroupCurrencySymbol(state);

  if (useDollars) {
    currencySymbol = DOLLAR_SIGN;
  }

  const dinero: string = Dinero({ amount: Math.floor(amount * 100) })
    .toFormat()

  return SPORTZINO_SYMBOLS.includes(currencySymbol) ?
    dinero.replace("$", currencySymbol + " ") :
    dinero.replace("$", currencySymbol)
};

export const formattedCurrencyToNumber = (value: string): number => {
  return parseFloat(value.replace(/[^0-9.]+/g, ""));
};

export const formatAsDollars = (amount: number) =>
  formatAsCurrency(amount, true);
