import LimitationForm from "./limitation-form";
import ExclusionForm from "./exclusion-form";
import useSelfExclusionsData from "./use-self-exclusions-data";
import SelfExclusionDisclaimer from "./self-exclusion-disclaimer";

export enum TYPES {
  SELF_LIMITATION_DEPOSIT = "SELF_LIMITATION_DEPOSIT",
  SELF_LIMITATION_BET = "SELF_LIMITATION_BET",
}

export enum LIMIT_TERMS {
  DAILY = "DAILY",
  WEEKLY = "WEEKLY",
  MONTHLY = "MONTHLY",
}

const ExclusionsAndLimitationsForms = () => {
  const {
    dailyDepositCurrent,
    dailyDepositNext,
    dailyWagerCurrent,
    dailyWagerNext,
    weeklyDepositCurrent,
    weeklyDepositNext,
    weeklyWagerCurrent,
    weeklyWagerNext,
    monthlyDepositCurrent,
    monthlyDepositNext,
    monthlyWagerCurrent,
    monthlyWagerNext,
    exclusions,
    refreshData,
  } = useSelfExclusionsData();

  const limitationFormProps = [
    {
      label: "Daily Deposit Limit",
      current: dailyDepositCurrent,
      next: dailyDepositNext,
      limitationType: TYPES.SELF_LIMITATION_DEPOSIT,
      limitTerm: LIMIT_TERMS.DAILY,
      refreshData,
    },
    {
      label: "Weekly Deposit Limit",
      current: weeklyDepositCurrent,
      next: weeklyDepositNext,
      limitationType: TYPES.SELF_LIMITATION_DEPOSIT,
      limitTerm: LIMIT_TERMS.WEEKLY,
      refreshData,
    },
    {
      label: "Monthly Deposit Limit",
      current: monthlyDepositCurrent,
      next: monthlyDepositNext,
      limitationType: TYPES.SELF_LIMITATION_DEPOSIT,
      limitTerm: LIMIT_TERMS.MONTHLY,
      refreshData,
    },
    {
      label: "Daily Pick Limit",
      current: dailyWagerCurrent,
      next: dailyWagerNext,
      limitationType: TYPES.SELF_LIMITATION_BET,
      limitTerm: LIMIT_TERMS.DAILY,
      refreshData,
    },
    {
      label: "Weekly Pick Limit",
      current: weeklyWagerCurrent,
      next: weeklyWagerNext,
      limitationType: TYPES.SELF_LIMITATION_BET,
      limitTerm: LIMIT_TERMS.WEEKLY,
      refreshData,
    },
    {
      label: "Monthly Pick Limit",
      current: monthlyWagerCurrent,
      next: monthlyWagerNext,
      limitationType: TYPES.SELF_LIMITATION_BET,
      limitTerm: LIMIT_TERMS.MONTHLY,
      refreshData,
    },
  ];

  const selfExclusion = exclusions?.find(
    (item) => item.limitation_type === "SELF_EXCLUSION"
  );

  return (
    <div style={{ marginTop: 20 }}>
      {selfExclusion ? (
        <SelfExclusionDisclaimer exclusion={selfExclusion} />
      ) : (
        <ExclusionForm refreshData={refreshData} />
      )}
      {limitationFormProps.map((props) => {
        return (
          <div key={`${props.limitationType}-${props.limitTerm}}`}>
            <hr></hr>
            <LimitationForm {...props} />
          </div>
        );
      })}
    </div>
  );
};

export default ExclusionsAndLimitationsForms;
