/**
 * @fileoverview SelectionHeader component displays the header and allows user to select the category of rewards to display
 */
import styles from "./styles.module.css";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { Form } from "react-bootstrap";
import { RewardEnum } from "utils/constants";
import React, { useState } from "react";
import { setSelectedCategory } from "redux/slices/avatar-modal-slice";
import { useDispatch } from "react-redux";

const SelectionHeader : React.FC = () => {
  const [selection, setSelection] = useState<RewardEnum>(RewardEnum.AVATAR);
  const dispatch = useDispatch();

  const handleCategoryChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedCategory = event.target.value as RewardEnum;

    setSelection(selectedCategory);
    dispatch(setSelectedCategory(selectedCategory));
  }

  return (
    <div className={styles.text}>
      <BlockyBoldText>
        Selection Library
      </BlockyBoldText>

      <div className="btn-group" style={{ width: "100%"}}>
        <Form.Select
          aria-label="Select category"
          className={`${styles.dropdownMenu}`}
          style={{
            backgroundColor: "black",
            color: "white",
            fontFamily: "Chakra Petch",
            fontWeight: 300
          }}
          onChange={handleCategoryChange}
          value={selection}
        >
          {Object.values(RewardEnum).map((value) => (
            <option
              key={value}
              value={value}
              style={{
                  }}
            >
              {`${value}s`}
            </option>
          ))}
        </Form.Select>
      </div>
    </div>
  )

}

export default SelectionHeader;