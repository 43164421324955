import { useAuth } from "hooks/auth";
import { Link, Redirect } from "react-router-dom";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { APPROVED, secondaryColor, sparketBlack, sparketLightGray, } from "utils/constants";
import Header from "components/header";
import SignoutButton from "./signoutButton";
import ProfileInfo from "./profileInfo";
import GroupDetails from "./group-details";
import { Button } from "react-bootstrap";
import Headroom from "react-headroom";
import { Mixpanel } from "hooks/mixPanel";
import { useAppSelector } from "redux/hooks";
import { getGroupState } from "redux/slices/group-slice";
import { getUserState } from "redux/slices/user-slice";
import NotificationConfiguration from "components/profile/notification-configuration";
import SparketScore from "./sparket-score/sparketScore";
import Avatar from "components/avatar/avatar";
import React from "react";
import PurchaseBanner from "components/purchase-banner/purchase-banner";
import { getAppConfig } from "redux/slices/app-config-slice";
import ImageUpload from "components/profile/imageUpload"

// Top half of profile
const ProfileSummary = (props: any) => {
  const appConfig = useAppSelector(getAppConfig);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <BlockyBoldText style={{ color: secondaryColor, fontSize: 30 }}>
        Profile
      </BlockyBoldText>
      {appConfig.enableBattlePass ? <Avatar /> : <ImageUpload />}
      <br></br>
      {/*<ImageUpload />*/}
      {/* Evan says identity verification will be upgraded but is causing pain right now so just commenting it out instead of deleting */}
      {/* <IdentityVerificationInfo /> */}
      <SparketScore />
      <br></br>
      <BlockyBoldText style={{ color: "white" }}>
        Signed in as {props.email}
      </BlockyBoldText>
    </div>
  );
};

// Bottom half of profile
const ProfileDetails = (props: any) => {
  return (
    // content TBD from design mocks
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <BlockyBoldText>Personal Info</BlockyBoldText>
      <ProfileInfo username={props.username}></ProfileInfo>
      {/* Evan says we will upgrade two factor auth so commenting this out instead of deleting */}
      {/* <BlockyBoldText>Two Factor Authentication</BlockyBoldText>
      <TwoFactorConfiguration /> */}

      <BlockyBoldText>Email Notification Settings</BlockyBoldText>
      <NotificationConfiguration />
    </div>
  );
};

const Profile = () => {
  const auth = useAuth();
  const group = useAppSelector(getGroupState);
  const username = useAppSelector(getUserState).user_name;

  if (auth.pending) {
    return <div>loading...</div>;
  }

  return (
    <>
      <Headroom>
        <Header />
      </Headroom>
      {auth.signedIn ? (
        <>
          <div
            style={{
              backgroundColor: sparketBlack,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              padding: 10,
            }}
          >
            <PurchaseBanner />
            <ProfileSummary email={auth.getEmail()} />
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", margin: 10 }}
          >
            <Link
              to="/edit-profile"
              style={{ textDecoration: "none" }}
              onClick={() => {
                Mixpanel.track("Clicked Edit Profile", {
                  isSignedIn: auth.signedIn,
                });
              }}
            >
              <Button
                style={{
                  backgroundColor: sparketLightGray,
                  color: sparketBlack,
                  border: `1px solid ${secondaryColor}`,
                  borderRadius: "unset",
                  height: 40,
                  width: 150,
                }}
              >
                <BlockyBoldText>Edit Profile</BlockyBoldText>
              </Button>
            </Link>
            <SignoutButton />
          </div>
          {/*<Link*/}
          {/*  style={{*/}
          {/*    display: "flex",*/}
          {/*    justifyContent: "center",*/}
          {/*    marginBottom: 10,*/}
          {/*  }}*/}
          {/*  to="/responsible-gaming"*/}
          {/*>*/}
          {/*  Responsible Gaming*/}
          {/*</Link>*/}
          <ProfileDetails username={username} />
          {group.status === APPROVED && group.settings.external_id_label && (
            <GroupDetails />
          )}
        </>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
};

export default Profile;
