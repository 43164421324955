import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { local as localStorage } from "utils/local-storage";
import { USER_SETTINGS_LOCAL_STORAGE_ITEM_NAME } from "utils/constants";

export interface UserSettings {
  groupSettings: UserGroupSettings[];
}

export interface UserGroupSettings {
  groupId: string,
  homePagePoolsEventIdFilter: string;
}

const loadInitialState: () => UserSettings = () => {
  const savedUserSettings = localStorage.getItem(USER_SETTINGS_LOCAL_STORAGE_ITEM_NAME);
  return savedUserSettings ? JSON.parse(savedUserSettings) : { groupSettings: [] } as UserSettings;
};

export const initialState = loadInitialState();

const slice = createSlice({
  name: "userSettings",
  initialState,
  reducers: {
    setHomePageEventIdFilter: (state, action: PayloadAction<{ groupId: string, eventId: string }>) => {
      const { groupId, eventId } = action.payload;
      const gs = state.groupSettings.find(group => group.groupId === groupId);
      if (gs) {
        gs.homePagePoolsEventIdFilter = eventId;
      } else {
        state.groupSettings.push({ groupId: groupId, homePagePoolsEventIdFilter: eventId } as UserGroupSettings);
      }
      localStorage.setItem(USER_SETTINGS_LOCAL_STORAGE_ITEM_NAME, JSON.stringify(state));
    },

    clearHomePageEventIdFilter: (state, action: PayloadAction<{ groupId: string }>) => {
      const { groupId } = action.payload;
      const gs = state.groupSettings.find(group => group.groupId === groupId);
      if (gs) {
        gs.homePagePoolsEventIdFilter = "";
      }
      localStorage.setItem(USER_SETTINGS_LOCAL_STORAGE_ITEM_NAME, JSON.stringify(state));
    }
  },
});
export const getUserGroupSettingsByGroupId = (state: RootState, groupId: string) => {
  return state.userSettings.groupSettings.find(group => group.groupId === groupId);
};

export const { setHomePageEventIdFilter, clearHomePageEventIdFilter } = slice.actions;

export default slice.reducer;
