import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Alert } from "react-bootstrap";
import ConfettiExplosion from "react-confetti-explosion";
import { getRequest } from "utils/httpClient";
import { PremiumProduct, PremiumProductMeta } from "interfaces/premium-product";
import useQueryParams from "utils/useQueryParams";
import { useDispatch } from "react-redux";
import { fetchRewards } from "components/avatar/initialize-rewards";

const PurchaseBanner = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const queryParams = useQueryParams();
  const premiumProductId = queryParams.get("xtl_order_id");

  const [premiumProduct, setPremiumProduct] = useState<PremiumProduct>();
  const [premiumProductsLoading, setPremiumProductLoading] = useState(false);

  const userIsSentToThePageAfterSuccessfulPurchaseAttempt =
    premiumProductId !== null && premiumProductId !== "";

  const [
    showSuccessfullyPurchasedProductBanner,
    setShowSuccessfullyPurchasedProductBanner
  ] = useState(userIsSentToThePageAfterSuccessfulPurchaseAttempt);

  useEffect(() => {
    setTimeout(() => {
      setShowSuccessfullyPurchasedProductBanner(false);
      history.push(history.location.pathname);
    }, 8000);
  }, [history]);

  useEffect(() => {
    if (userIsSentToThePageAfterSuccessfulPurchaseAttempt) {
      setTimeout(() => {
        fetchRewards(dispatch);
        setTimeout(() => fetchRewards(dispatch), 5000);
      }, 2000);

      setPremiumProductLoading(true);
      getRequest("/v2/premium-products")
        .then((products: PremiumProduct[]) =>
          products.map(p => {
            if (typeof p.meta === "string") {
              const decodedMeta = atob(p.meta);
              p.meta = JSON.parse(decodedMeta) as PremiumProductMeta;
            }
            return p;
          })
        )
        .then(products => setPremiumProduct(products.find(value => value.id === premiumProductId)))
        .finally(() => setPremiumProductLoading(false));
    }
  }, [userIsSentToThePageAfterSuccessfulPurchaseAttempt]);

  return (
    <div className={"d-flex justify-content-center"} style={{ width: "100%" }}>
      <Alert show={showSuccessfullyPurchasedProductBanner && !premiumProductsLoading}
             variant="success"
             className={"mt-4"}
             style={{
               width: "60%",
               maxWidth: "500px",
               textAlign: "center"
             }}
      >
        You just purchased {premiumProduct?.name}!
      </Alert>

      {showSuccessfullyPurchasedProductBanner && !premiumProductsLoading && (
        <ConfettiExplosion
          zIndex={1001}
          particleCount={400}
          duration={4000}
          width={2000}
          style={{
            position: "absolute",
            top: "20%",
            left: "55%"
          }}
        />
      )}
    </div>
  );
};

export default PurchaseBanner;
