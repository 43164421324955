import { Modal } from "react-bootstrap";
import styles from "./styles.module.scss";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { getGroupState } from "redux/slices/group-slice";
import { Dispatch, SetStateAction } from "react";
import { setGroup, fetchGroupCustomization } from "redux/slices/group-slice";
import { CaretRightFill } from "react-bootstrap-icons";
import { sparketBlack } from "utils/constants";
import UserGroupsResponse from "interfaces/user-groups-response";
import { useHistory } from "react-router-dom";

interface Props {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  availableGroups: UserGroupsResponse[];
}
const Container = ({ show, setShow, availableGroups }: Props) => {
  const group = useAppSelector(getGroupState);
  const dispatch = useAppDispatch();
  const history = useHistory();

  return (
    <Modal
      show={show}
      dialogClassName="modal-90w"
      onHide={() => setShow(false)}
    >
      <Modal.Body className={styles.body}>
        {availableGroups &&
          availableGroups.map((groupOption) => {
            const isCurrentGroup = group.id === groupOption.id;
            return (
              <div
                key={groupOption.id}
                className={styles.groupButton}
                onClick={() => {
                  dispatch(setGroup(groupOption));
                  // Call to v2/users/groups/list endpoint does not return group customizations
                  // -> need to be loaded with fetchGroupCustomization
                  dispatch(fetchGroupCustomization(groupOption.id));
                  history.push("/");
                  setShow(false);
                }}
              >
                <BlockyBoldText>
                  {isCurrentGroup ? (
                    <CaretRightFill className={styles.currentGroupIon} />
                  ) : (
                    <CaretRightFill style={{ color: sparketBlack }} />
                  )}{" "}
                  {groupOption.name}
                </BlockyBoldText>
              </div>
            );
          })}
      </Modal.Body>
    </Modal>
  );
};

export default Container;
