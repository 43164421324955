/**
 * @fileoverview InitializeRewards component fetches available rewards from the server and stores them in the Redux store.
 */
import { DEFAULT_AVATAR_REWARD, Reward, RewardEnum } from "utils/constants";
import { setLoading, setRewards } from "redux/slices/avatar-modal-slice";
import { useDispatch } from "react-redux";
import { Dispatch, useEffect } from "react";
import { getRewardsPath } from "utils/backend-path-builders";
import { getRequest } from "utils/httpClient";

export interface AvailableRewards {
  user_id: string;
  rewards: {
    id: string;
    type: string;
    url: string;
    name: string;
    description: string;
    unlocked: boolean;
  }[];
}

/**
 * Maps the reward type in response to a RewardEnum, allowing the response to be stored as Reward type.
 */
const mapToRewardEnum = (type: string): RewardEnum => {
  switch (type) {
    case "avatar":
      return RewardEnum.AVATAR;
    case "badge":
      return RewardEnum.BADGE;
    default:
      throw new Error(`Unexpected Reward Type: ${type}`);
  }
};

/**
 * Extracts the rewards from the response and maps them to the Reward type.
 */
const extractRewards = (json: AvailableRewards): Reward[] => {
  return [
    DEFAULT_AVATAR_REWARD,
    ...json.rewards.map(reward => ({
      id: reward.id,
      type: mapToRewardEnum(reward.type),
      url: reward.url,
      name: reward.name,
      description: reward.description,
      unlocked: reward.unlocked
    }))
  ];
};

export const fetchRewards = async (dispatch: Dispatch<any>) => {
  const path = getRewardsPath();
  const response = await getRequest(path);
  const rewards = extractRewards(response);
  dispatch(setRewards(rewards));
  dispatch(setLoading(false));
};

const InitializeRewards = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    fetchRewards(dispatch);
  }, []);
};

export default InitializeRewards;