import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import Header from "components/header";
import { useEffect, useState } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { secondaryColor, sparketBlack, sparketLightGray } from "utils/constants";
import { getRequest, postRequest } from "utils/httpClient";
import SubmitProfile from "../submitProfile";
import Button from "react-bootstrap/Button";
import Headroom from "react-headroom";
import { Form, InputGroup, Spinner } from "react-bootstrap";
import styles from "./styles.module.css";
import { useAuth } from "hooks/auth";
import LoadingSpinner from "components/loading-spinner.tsx";
import CountrySelect from "react-bootstrap-country-select";
import "react-bootstrap-country-select/dist/react-bootstrap-country-select.css";
import { Mixpanel } from "hooks/mixPanel";
import { DatePicker } from "react-rainbow-components";
import { toast } from "react-toastify";
import {
  getUserPath,
  getUsersPersonalInformationPath,
  getValidatePotentialUsernamePath,
} from "utils/backend-path-builders";
import { useAppSelector } from "redux/hooks";
import { getUserState } from "redux/slices/user-slice";
import Avatar from "../../avatar/avatar";
import { getAppConfig } from "redux/slices/app-config-slice";
import BlockyMediumText from "components/custom-texts/blocky-medium-text";
import ImageUpload from "components/profile/imageUpload";

export const MIN_AGE_ALLOWED = 18;

const ProfileEdit = () => {
  const appConfig = useAppSelector(getAppConfig);
  const [loading, setLoading] = useState(false);
  const [callbackMessage, setCallbackMessage] = useState("");
  const [country, setCountry] = useState("");
  const [invalidUsername, setInvalidUsername] = useState(true);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState<Date>(new Date());
  const [username, setUsername] = useState("");
  const [status, setStatus] = useState("");
  const [bio, setBio] = useState("");
  const [avatar, setAvatar] = useState("");
  const [backdrop, setBackdrop] = useState("");
  const [initialUsername, setInitialUsername] = useState("");
  const [userId, setUserId] = useState("");
  const [isNewUser, setIsNewUser] = useState(false);
  const user = useAppSelector(getUserState);
  useEffect(() => {
    getRequest(getUsersPersonalInformationPath())
      .then((data) => {
        const {
          first_name,
          last_name,
          postal_code,
          date_of_birth,
          country,
        } = data;
        if (!first_name) {
          setIsNewUser(true);
          setCountry("us");
        } else {
          setCountry(country);
          setFirstName(first_name);
          setLastName(last_name);
          setPostalCode(postal_code);
          const parsedDate = date_of_birth.split("-");
          setDateOfBirth(
            new Date(parsedDate[0], --parsedDate[1], parsedDate[2])
          );
        }
      })
      .catch((err) => {
        console.warn(err);
      });
  }, []);

  const ageDifMs = Date.now() - dateOfBirth.getTime();
  const ageDate = new Date(ageDifMs); // miliseconds from epoch
  const ageInYears = Math.abs(ageDate.getUTCFullYear() - 1970);

  const dateOfBirthError = ageInYears < MIN_AGE_ALLOWED;

  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (username === initialUsername) {
        setCallbackMessage("");
        setInvalidUsername(false);
      } else if (username === "") {
        setCallbackMessage(
          "Please choose a username. You cannot make a pick if you haven't entered a username!"
        );
        setInvalidUsername(true);
      } else {
        // check if alias is unique
        postRequest(getValidatePotentialUsernamePath(), {
          body: { user_name: username },
        })
          .then(() => {
            setCallbackMessage("The username " + username + " is available!");
            setInvalidUsername(false);
          })
          .catch((e) => {
            if (e.response === undefined || e.response.data === undefined) {
              setCallbackMessage(
                "Sorry, our servers cannot determine uniqueness."
              );
            } else if (e.response.data.error_code === 5000) {
              setCallbackMessage(
                "Username " +
                username +
                " is taken. Please try another username."
              );
              setInvalidUsername(true);
            } else {
              setCallbackMessage(e.response.data.friendly_error_message);
            }
          });
      }
    }, 500);
    return () => clearTimeout(timeOutId);
  }, [username, initialUsername]);

  useEffect(() => {
    // get Alias data from users me API
    getRequest(getUserPath())
      .then((data) => {
        setInitialUsername(data.user_name);
        setUsername(data.user_name || "");
        data.user_name ? setInvalidUsername(false) : setInvalidUsername(true);
        setBio(data.bio);
        setStatus(data.status);
        setAvatar(data.avatar);
        setBackdrop(data.backdrop);
        setUserId(data.id);
      })
      .catch((err) => {
        if (err === "No current user") {
          console.log("Must sign in to view profile info");
        } else {
          // something unexpectedly bad happened...
          console.warn(err);
        }
      });
  }, []);

  const history = useHistory();
  const auth = useAuth();

  if (auth.pending) {
    return <LoadingSpinner />;
  } else if (!auth.signedIn) {
    return <Redirect to="signin" />;
  }

  return (
    <>
      <Headroom>
        <Header />
      </Headroom>
      <div
        style={{
          backgroundColor: sparketBlack,
          color: "white",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          padding: 10,
        }}
      >
        {appConfig.enableBattlePass ?
          <Avatar /> :
          <div>
            <BlockyMediumText style={{ color: secondaryColor }}>
              Edit Profile
            </BlockyMediumText>
            <ImageUpload />
            <BlockyMediumText>
              Click image above to upload profile picture
            </BlockyMediumText>
          </div>
        }
      </div>
      <div>
        <div className={styles.profileContainer}>
          <div style={{ padding: 10 }}>
            <BlockyBoldText>Profile Info</BlockyBoldText>
          </div>
          <InputGroup style={{ width: "90%" }}>
            <Form.Label className={styles.labelStyles}>Username *</Form.Label>
            <Form.Control
              required
              isInvalid={invalidUsername}
              style={{ backgroundColor: sparketLightGray }}
              className={styles.inputStyles}
              placeholder="Username"
              onChange={(e) => {
                setUsername(e.target.value);
              }}
              value={username}
            />
            <div
              style={
                invalidUsername
                  ? { color: "red", paddingBottom: 10 }
                  : { color: "green", paddingBottom: 10 }
              }
            >
              {callbackMessage}
            </div>
          </InputGroup>
          <Form.Label className={styles.labelStyles}>First Name *</Form.Label>
          <Form.Control
            style={{ backgroundColor: sparketLightGray }}
            className={styles.inputStyles}
            placeholder="First Name"
            onChange={(e) => setFirstName(e.target.value)}
            value={firstName}
            isInvalid={firstName === ""}
          />
          <Form.Label className={styles.labelStyles}>Last Name *</Form.Label>
          <Form.Control
            style={{ backgroundColor: sparketLightGray }}
            className={styles.inputStyles}
            placeholder="Last Name"
            onChange={(e) => setLastName(e.target.value)}
            value={lastName}
            isInvalid={lastName === ""}
          />

          <Form.Label className={styles.labelStyles}>Country *</Form.Label>
          <div
            className={`${styles.labelStyles} country-picker`}
            style={{ backgroundColor: sparketLightGray, marginBottom: 10 }}
          >
            <CountrySelect
              value={country}
              onChange={setCountry}
              valueAs="id"
              // Custom sort places United States first in list
              sort={(c1: any, c2: any) => {
                if (c1.id === "us") return -1;
                else if (c2.id === "us") return 1;
                else if (c1.name < c2.name) return -1;
                else if (c1.name > c2.name) return 1;
                else return 0;
              }}
            />
            {country === null && (
              <div style={{ color: "red" }}>Country is required</div>
            )}
          </div>

          <Form.Label className={styles.labelStyles}>Postal Code *</Form.Label>
          <Form.Control
            style={{ backgroundColor: sparketLightGray }}
            className={styles.inputStyles}
            placeholder="Postal Code"
            onChange={(e) => setPostalCode(e.target.value)}
            value={postalCode}
            isInvalid={postalCode === ""}
          />
          <Form.Label className={styles.labelStyles}>
            Date of Birth *
          </Form.Label>
          <div
            className={`${styles.labelStyles} `}
            style={{ marginBottom: 10 }}
          >
            <DatePicker
              error={
                dateOfBirthError ? `must be ${MIN_AGE_ALLOWED} or older` : ""
              }
              maxDate={new Date()}
              value={dateOfBirth}
              onChange={(value) => {
                setDateOfBirth(value);
              }}
              formatStyle="large"
              locale="en-US"
            />
          </div>
          <Button
            disabled={
              invalidUsername ||
              dateOfBirthError ||
              firstName === "" ||
              lastName === "" ||
              country === null ||
              loading
            }
            style={{
              backgroundColor: secondaryColor,
              borderRadius: "unset",
              border: "none",
              color: sparketBlack,
              width: "90%",
              height: 45,
            }}
            onClick={() => {
              Mixpanel.track("Clicked Submit Profile", {
                isSignedIn: auth.signedIn,
              });
              setLoading(true);
              SubmitProfile({
                firstName,
                lastName,
                country,
                postalCode,
                dateOfBirth,
                username,
                status,
                bio,
                avatar,
                backdrop,
                userId,
                notifications: user.notifications,
              })
                .then((data) => {
                  if (isNewUser) {
                    history.push("/");
                  } else {
                    history.push("/profile");
                  }
                })
                .catch((error) => {
                  if (error.response?.data?.technical_error) {
                    toast.error(error.response.data.technical_error);
                  } else {
                    toast.error("Something went wrong. Please try again.");
                  }
                  setLoading(false);
                });
            }}
          >
            {loading ? (
              <Spinner animation="border" />
            ) : (
              <BlockyBoldText>Submit Profile</BlockyBoldText>
            )}
          </Button>
        </div>
      </div>
    </>
  );
};

export default ProfileEdit;
