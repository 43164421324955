import { Modal } from "react-bootstrap";
import LoadingSpinner from "components/loading-spinner.tsx";
import { useEffect, useState } from "react";
import styles from "./styles.module.css";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import ReactMarkdown from "react-markdown";
import { toast } from "react-toastify";
import { CONTEST_TERMS_HEADER, textColor } from "utils/constants";
import { useAppSelector } from "redux/hooks";
import { getGroupState, getGroupStyles } from "redux/slices/group-slice";
import { Contest } from "interfaces/leaderboard/contest";

interface LegalModalProps {
  show: boolean;
  hideModal: () => void;
  header: string;
  endPoint: string;
}
const LegalModal = ({ show, hideModal, header, endPoint }: LegalModalProps) => {
  const [loading, setLoading] = useState(true);
  const [body, setBody] = useState("");
  const groupState = useAppSelector(getGroupState);
  useEffect(() => {
    if (!show) return;

    if (header === CONTEST_TERMS_HEADER) {
      const selectedContest: Contest | undefined = groupState.selectedContest;
      const body: string = selectedContest?.contest_terms || "**No Contest Terms**";
      setBody(body);
      setLoading(false);
    } else {
      fetch(endPoint)
        .then((res) => res.json())
        .then((data) => setBody(data.doc as string))
        .catch(() => setBody(""))
        .finally(() => setLoading(false));
    }
  }, [body, endPoint, show]);
  const primaryColor = useAppSelector(getGroupStyles).primary_color;

  return (
    <>
      <Modal show={show} onHide={hideModal} size="lg">
        <Modal.Header style={{backgroundColor: primaryColor}} closeButton>
          <Modal.Title>
            <BlockyBoldText style={{color: textColor}}>{header}</BlockyBoldText>
          </Modal.Title>
        </Modal.Header>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <Modal.Body className={styles.legalModalBody}>
            <ReactMarkdown>{body}</ReactMarkdown>
          </Modal.Body>
        )}
      </Modal>
    </>
  );
};
export default LegalModal;
