import {
  sparketBlack,
  WAITING,
  ALLOWED,
  DENIED,
  sparketDarkRed,
  XPOINT_NOT_FOUND,
} from "utils/constants";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import ErrorBadgeContent from "./badge-content/error";
import WaitingBadgeContent from "./badge-content/waiting";
import AllowedBadgeContent from "./badge-content/allowed";
import { GroupSettings } from "interfaces/group-settings";
import { useAppSelector } from "redux/hooks";
import { getGroupState } from "redux/slices/group-slice";
import { secondaryColor } from "utils/constants";
import styles from "./badge-styles.module.css";

interface Props {
  status: string;
  showGeolocationModal: Dispatch<SetStateAction<boolean>>;
}

const THREE_SECONDS_FOR_CHECKMARK_ANIMATION = 3000;

const GeolocationStatus = ({ status, showGeolocationModal }: Props) => {
  const getContent = (status: string) => {
    switch (status) {
      case XPOINT_NOT_FOUND:
        return () => (
          <ErrorBadgeContent
            message="Xpoint not found"
            showGeolocationModal={showGeolocationModal}
          />
        );
      case WAITING:
        return () => <WaitingBadgeContent />;
      case ALLOWED:
        return () => <AllowedBadgeContent />;
      case DENIED:
        return () => (
          <ErrorBadgeContent
            message="Geolocation error"
            showGeolocationModal={showGeolocationModal}
          />
        );
      default:
        return () => <div></div>;
    }
  };

  const Content = useMemo(() => getContent(status), [status]);
  const [show, setShow] = useState(true);
  const groupSettings: GroupSettings = useAppSelector(getGroupState).settings;

  useEffect(() => {
    if (status === ALLOWED) {
      setTimeout(() => {
        setShow(false);
      }, THREE_SECONDS_FOR_CHECKMARK_ANIMATION);
    } else {
      setShow(true);
    }
  }, [status]);

  if (!show || !groupSettings.enable_geolocation) {
    return <div></div>;
  }

  return (
    <div className={styles.outerWrapper}>
      <div
        style={{
          backgroundColor: status === DENIED ? sparketDarkRed : sparketBlack,
          color: status === DENIED ? "white" : secondaryColor,
        }}
        className={styles.innerWrapper}
      >
        <Content />
      </div>
    </div>
  );
};

export default GeolocationStatus;
