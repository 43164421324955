import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import Button from "react-bootstrap/Button";
import { useHistory } from "react-router-dom";
import { secondaryColor, sparketBlack, textColor } from "utils/constants";
import { useAppSelector } from "redux/hooks";
import { getGroupStyles } from "redux/slices/group-slice";

interface ViewPoolLinkProps {
  poolId: string;
}
const ViewPoolLink = (props: ViewPoolLinkProps) => {
  const primaryColor = useAppSelector(getGroupStyles).primary_color;
  const history = useHistory();
  return (
    <Button
      style={{
        padding: 10,
        borderRadius: 2,
        backgroundColor: secondaryColor,
        border: "none",
        color: sparketBlack,
      }}
      onClick={() => history.push(`/pools/${props.poolId}`)}
    >
      <BlockyHeavyText style={{color: "black"}}>{"View Pool >"}</BlockyHeavyText>
    </Button>
  );
};

export default ViewPoolLink;
