import { Button, Modal } from "react-bootstrap";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { Mixpanel } from "hooks/mixPanel";
import { useState, useEffect } from "react";
import { useAppSelector } from "redux/hooks";
import { getGroupState } from "redux/slices/group-slice";
import { sparketBlack, secondaryColor } from "utils/constants";
import BlockyMediumText from "components/custom-texts/blocky-medium-text";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import BlockyLightText from "components/custom-texts/blocky-light-text";

interface Props {
  show: boolean;
}
const UnsupportedBrowserAlert = ({ show }: Props) => {
  const group = useAppSelector(getGroupState);
  const groupSettings = group.settings;

  const [linkCoppied, setLinkCoppied] = useState(false);

  useEffect(() => {
    if (linkCoppied) {
      setTimeout(() => setLinkCoppied(false), 2000);
    }
  }, [linkCoppied]);

  const currentUrl = window.location.href;

  const removeQueryParams = (str: string) => {
    if (str.includes("?")) {
      return str.split("?")[0];
    }

    return str;
  };

  return (
    <Modal show={show} fullscreen backdrop="static" keyboard={false}>
      <Modal.Body
        style={{
          height: "98vh",
          backgroundColor: sparketBlack,
          color: "white",
          border: `5px solid ${groupSettings.primary_color}`,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <img src={groupSettings.logo_url} height={52} />
          <BlockyBoldText style={{ fontSize: 18, paddingTop: 20 }}>
            Welcome to {group.name}
          </BlockyBoldText>
          <BlockyLightText
            style={{
              fontSize: 12,
              paddingBottom: 30,
              display: "flex",
              alignItems: "baseline",
            }}
          >
            Powered By
            <BlockyHeavyText style={{ color: secondaryColor, fontSize: 13 }}>
              &nbsp;SPARKET
            </BlockyHeavyText>
          </BlockyLightText>
          <BlockyMediumText style={{ paddingBottom: 30 }}>
            No download is required to play.
          </BlockyMediumText>

          <BlockyMediumText>
            Just copy this link and paste it into a supported browser on your
            device to get in the game!
          </BlockyMediumText>

          <div
            style={{
              display: "flex",
              padding: 20,
              alignItems: "center",
              fontSize: 12,
            }}
          >
            <BlockyMediumText style={{ wordBreak: "break-all" }}>
              {removeQueryParams(currentUrl)}
            </BlockyMediumText>
            <Button
              style={{ marginLeft: 10 }}
              onClick={() => {
                Mixpanel.track("Coppied Link from unsupported browser", {
                  link: currentUrl,
                });
                var tempInput = document.createElement("input");
                (tempInput as any).style =
                  "position: absolute; left: -1000px; top: -1000px";
                tempInput.value = currentUrl;
                document.body.appendChild(tempInput);
                tempInput.select();
                document.execCommand("copy");
                document.body.removeChild(tempInput);
                setLinkCoppied(true);
              }}
              variant={linkCoppied ? "outline-success" : "outline-secondary"}
            >
              {linkCoppied ? "Copied!" : "Copy"}
            </Button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default UnsupportedBrowserAlert;
