import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import * as storage from "utils/local-storage";
import { IS_INTEGRATED_APP, USER_ID, TOKEN } from "utils/constants";

export interface IntegratedAppSlice {
  isIntegratedApp: boolean;
  userId?: string;
  token?: string;
}

const initialState: IntegratedAppSlice = {
  isIntegratedApp: false,
};

const slice = createSlice({
  name: "integratedApp",
  initialState,
  reducers: {
    setIntegratedState: (state, action) => {
      state.isIntegratedApp = action.payload.isIntegratedApp;
      state.userId = action.payload.userId;
      state.token = action.payload.token;

      storage.local.setItem(IS_INTEGRATED_APP, "true");
      if (state.userId) {
        storage.local.setItem(USER_ID, state.userId);
      }
      if (state.token) {
        storage.local.setItem(TOKEN, state.token);
      }

      return state;
    },
    removeIntegratedState: (state) => {
      state.isIntegratedApp = false;
      state.userId = "";
      state.token = "";

      storage.local.removeItem(IS_INTEGRATED_APP);
      storage.local.removeItem(USER_ID);
      storage.local.removeItem(TOKEN);

      return state;
    }
  },
});

export const { setIntegratedState,removeIntegratedState } = slice.actions;

export const getIntegratedAppState = (state: RootState) => {
  return state.integratedApp;
};

export default slice.reducer;
