import { UICustomizations } from "interfaces/ui-customizations";
import { PageFunctionalityContent } from "./page-functionality-constants";

interface SectionRemoval {
  section: string;
  row?: number;
}

const removeContentRow = (modified: string[], originalRowNumber: number): string[] => {
  if (originalRowNumber >= 0 && originalRowNumber < modified.length) {
    return modified.filter((_, index) => index !== originalRowNumber);
  }
  return modified;
};

export const filterContent = (
  customization: UICustomizations,
  content: PageFunctionalityContent[],
  customizationMapping: { [key in keyof UICustomizations]: SectionRemoval[] }): PageFunctionalityContent[] => {
  return content.reduce((filteredContent: PageFunctionalityContent[], section: PageFunctionalityContent) => {
    let includeSection = true;
    let modifiedSection = { ...section, body: [...section.body] };

    for (const [customizationKey, removals] of Object.entries(customizationMapping)) {
      const isCustomizationEnabled = customization[customizationKey as keyof UICustomizations];

      if (!isCustomizationEnabled) {
        for (const removal of removals) {
          if (removal.section === section.title) {
            if (removal.row !== undefined) {
              modifiedSection.body = removeContentRow(modifiedSection.body, removal.row);
            } else {
              includeSection = false;
            }
          }
        }
      }
    }

    if (includeSection) {
      filteredContent.push(modifiedSection);
    }

    return filteredContent;
  }, []);
};
