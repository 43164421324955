import { Button } from "react-bootstrap";
import { useAppDispatch } from "redux/hooks";
import { sparketBlack, textColor } from "utils/constants";
import { open } from "redux/slices/sparket-tutorial-slice";
import BlockyBoldText from "../custom-texts/blocky-bold-text";

const TitleBar = () => {
  const dispatch = useAppDispatch();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: sparketBlack,
        justifyContent: "space-between",
        height: 45,
        paddingLeft: 25,
      }}
    >
      <BlockyBoldText
        customStyles={{
          color: "white",
          fontSize: 30,
          display: "flex",
        }}
      >
        Help
      </BlockyBoldText>
      <Button
        style={{
          backgroundColor: sparketBlack,
          border: "none",
          color: "white",
        }}
        onClick={() => dispatch(open())}
      >
        Launch Tutorial
      </Button>
    </div>
  );
};

export default TitleBar;
