import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { SEEN_CONTEST_POPUP_URLS } from "components/contest-popup/modal";

export interface ContestPopupModalState {
  open: boolean;
  allowModalOpen: boolean;
}

const initialState: ContestPopupModalState = {
  open: false,
  allowModalOpen: true,
};

const slice = createSlice({
  name: "contestPopupModal",
  initialState,
  reducers: {
    openContestPopup: (state) => {
      if (state.allowModalOpen) {
        state.open = true;
      }
    },
    blockContestModal: (state, action) => {
      state.allowModalOpen = !action.payload;
    },
    closeContestPopup: (state, action) => {
      const seenPopupUrls = localStorage.getItem(SEEN_CONTEST_POPUP_URLS);
      const newSeenPopupUrls = seenPopupUrls + " " + action.payload;
      localStorage.setItem(SEEN_CONTEST_POPUP_URLS, newSeenPopupUrls);
      state.open = false;
    },
  },
});

export const {
  openContestPopup,
  closeContestPopup,
  blockContestModal,
} = slice.actions;

export const getContestModalState = (state: RootState) => {
  return state.contestPopupModal;
};

export default slice.reducer;
