import { Button, Spinner } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { useAppSelector } from "redux/hooks";
import { secondaryColor, sparketBlack } from "utils/constants";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { getRequest } from "utils/httpClient";
import { PremiumProduct, PremiumProductMeta } from "interfaces/premium-product";
import { getUserState, UserState } from "redux/slices/user-slice";
import { BattlePass, BattlePassUserLevels, GroupBattlePasses } from "interfaces/battle-pass";
import { getGroupState } from "redux/slices/group-slice";
import dateFormatter from "utils/formatter-utils/date-formatter";
import { Check } from "react-bootstrap-icons";

interface BattlePassAndProduct {
  battlePass: BattlePass;
  product?: PremiumProduct;
}

const AvailableProductsDisplay = ({}) => {
  const userState: UserState = useAppSelector(getUserState);

  const [selectedProduct, setSelectedProduct] = useState<PremiumProduct | null>(null);

  const {
    battlePassesWithProductsAvailableForPurchase,
    loading
  } = useBattlePassesWithProductsAvailableForPurchase();

  useEffect(() => {
    if (battlePassesWithProductsAvailableForPurchase.length > 0 && !selectedProduct) {
      setSelectedProduct(battlePassesWithProductsAvailableForPurchase[0].product!!);
    }
  }, [battlePassesWithProductsAvailableForPurchase]);

  const handleProductClick = (product: PremiumProduct) => {
    setSelectedProduct(prevSelectedReward =>
      prevSelectedReward?.id === product.id ? null : product
    );
  };

  const handlePurchaseButtonClick = () => {

    const buildPaymentUrl = () => {
      const currentPageUrl = window.location.href;

      const selectedProductMeta = selectedProduct?.meta as PremiumProductMeta;
      const inovioProduct = selectedProductMeta.inovio_product!;

      return `https://api.inoviopay.com/paymentpage/3ds2/${inovioProduct.client_id}/${inovioProduct.site_id}/${inovioProduct.product_id}` +
        "?request_enrollment=1" +
        "&merch_ac_id=187744" +
        `&PG_REDIR=1` +
        `&PG_SUCC_URL=${currentPageUrl}` +
        `&XTL_UDF01=${userState.id}` +
        `&XTL_ORDER_ID=${selectedProduct?.id!}` +
        "&PGCSS=https://www.sparket.app/inovio/payment-page-styles.css";
    };

    window.location.href = buildPaymentUrl();
  };

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          height: 90
        }}
      >
        <Spinner animation="border" variant="warning" />
      </div>
    );
  }

  if (battlePassesWithProductsAvailableForPurchase.length === 0) {
    return <div style={{ color: "white", padding: "1rem" }}>
      No upgrades are currently available.<br />Select ‘Edit Avatar’ to equip purchased upgrades.
    </div>;
  }

  return (
    <div>
      {battlePassesWithProductsAvailableForPurchase.map(battlePassAndProductPair => {
        const battlePass = battlePassAndProductPair.battlePass;
        const product = battlePassAndProductPair.product!;
        return (

          <div
            key={battlePass.id}
            onClick={() => handleProductClick(product)}
            className={"p-2"}
            style={{
              outline: selectedProduct?.id === product.id ? `3px solid ${secondaryColor}` : "1px solid gray",
              cursor: "pointer",
              color: "white",
            }}
          >

            <div className="row">
              <div className="col-10">
                <div>Name: {battlePass.name}</div>
                <div>Description: {battlePass.description}</div>
                <div>Start: {dateFormatter(battlePass.begin)}</div>
                <div>Finish: {dateFormatter(battlePass.finish)}</div>
                <div>Price: ${(product.meta as PremiumProductMeta).price}</div>
              </div>
              <div className="col-2 d-flex align-items-start justify-content-end">
                {selectedProduct?.id === product.id && (
                  <Check
                    size={40}
                    color="green"
                  />
                )}
              </div>
            </div>
          </div>
        );
      })}
      <Button
        className={"mt-3"}
        style={{
          backgroundColor: secondaryColor,
          color: sparketBlack,
          border: "none",
          borderRadius: "unset",
          height: 40,
          width: 150,
        }}
        disabled={!selectedProduct}
        onClick={handlePurchaseButtonClick}
      >
        <BlockyBoldText>Purchase</BlockyBoldText>
      </Button>
    </div>
  );
};


const useBattlePassesWithProductsAvailableForPurchase = () => {
  const group = useAppSelector(getGroupState);

  const [
    battlePassesWithProductsAvailableForPurchase,
    setBattlePassesWithProductsAvailableForPurchase] = useState<BattlePassAndProduct[]>([]);

  const [loading, setLoading] = useState<boolean>(false);

  const fetchPremiumProducts = async (): Promise<PremiumProduct[]> => {
    const products = await getRequest("/v2/premium-products");
    return products.map((p: PremiumProduct) => {
      if (typeof p.meta === "string") {
        const decodedMeta = atob(p.meta);
        p.meta = JSON.parse(decodedMeta) as PremiumProductMeta;
      }
      return p;
    });
  };

  const fetchGroupBattlePasses = async (): Promise<GroupBattlePasses> =>
    getRequest(`/pass/details/group/${group.id}`);

  const fetchBattlePassUserLevels = async (): Promise<BattlePassUserLevels> =>
    getRequest("/pass/user/all");

  const computeBattlePassesAvailableForPurchase = (
    premiumProducts: PremiumProduct[],
    battlePasses: BattlePass[],
    battlePassUserLevels: BattlePassUserLevels
  ): BattlePassAndProduct[] => {
    const purchasedPassIds = [
      ...(battlePassUserLevels.expired ?? []),
      ...(battlePassUserLevels.active ?? []),
      ...(battlePassUserLevels.upcoming ?? [])
    ]
      .filter(bp => bp.purchased)
      .map(bt => bt.pass_id);

    const notPurchasedNonExpiredPasses = battlePasses
      .filter(battlePass => !purchasedPassIds.includes(battlePass.id))
      .filter(battlePass => battlePass.finish > Date.now());

    return notPurchasedNonExpiredPasses
      .map(battlePass => {
        const battlePassProduct = premiumProducts.find(premiumProduct => {
          const meta = premiumProduct.meta as PremiumProductMeta;
          return meta.product_type === "battle-pass" && meta.battle_pass?.id === battlePass.id;
        });

        return {
          product: battlePassProduct,
          battlePass: battlePass
        } as BattlePassAndProduct;
      })
      .filter(battlePassAndProduct => battlePassAndProduct.product);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const [premiumProducts, groupBattlePasses, passUserLevels] = await Promise.all([
          fetchPremiumProducts(),
          fetchGroupBattlePasses(),
          fetchBattlePassUserLevels()
        ]);

        const battlePassAndProductPairs = computeBattlePassesAvailableForPurchase(
          premiumProducts,
          groupBattlePasses.passes,
          passUserLevels
        );

        setBattlePassesWithProductsAvailableForPurchase(battlePassAndProductPairs);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [group.id]);

  return { battlePassesWithProductsAvailableForPurchase, loading };
};

export default AvailableProductsDisplay;
