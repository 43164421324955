import useDeviceDimensions from "hooks/useDeviceDimensions";
import { Contest } from "interfaces/leaderboard/contest";
import { useEffect, useState } from "react";
import { Typeahead } from "react-bootstrap-typeahead";
import { useAppSelector } from "redux/hooks";
import { getGroupState } from "redux/slices/group-slice";
import { getRequest } from "utils/httpClient";
import { Form } from "react-bootstrap";
import { Phase } from "interfaces/leaderboard/phase";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { getContestsPath } from "utils/backend-path-builders";
import { Option } from "components/leaderboard/leaderboard";
import { toast } from "react-toastify";

interface Props {

  selectedContest: Contest | undefined;
  setContest: (contest: Contest | undefined) => void;

  selectedPhase: Phase | undefined;
  setPhase: (phase: Phase | undefined) => void;

  disabled: boolean
  onContestLoaded: () => void;
}

const ContestAndPhaseSelector = ({ selectedContest, setContest, selectedPhase, setPhase, disabled, onContestLoaded }: Props) => {

  const group = useAppSelector(getGroupState);
  const groupId = group.id;

  const [contests, setContests] = useState<Contest[]>([]);
  const [contestsLoading, setContestsLoading] = useState(false);

  useEffect(() => {
    if (!group.id) {
      return;
    }
    setContestsLoading(true);
    const path = getContestsPath(groupId);
    getRequest(path, { skipIntegrationApi: true })
      .then((response: Contest[]) => {
        const nonDeletedContests = response.filter((contest: Contest) => !contest.is_deleted);
        setContests(nonDeletedContests);
        setActivePhaseAndContest(nonDeletedContests);
      })
      .catch((e) => {
        console.error("failed to load contests", e);
        toast.error(`Something went wrong.`);
      })
      .finally(() => {
        setContestsLoading(false);
        onContestLoaded();
      });
  }, [group.id]);


  const setActivePhaseAndContest = (contests: Contest[]) => {
    const activeContest = contests.find((contest: Contest) => contest.is_active);

    if (activeContest) {
      setContest(activeContest);

      const activePhase = activeContest.phases?.find(phase => phase.is_active);
      setPhase(activePhase);
    }
  };

  const { isMobile } = useDeviceDimensions();

  if ((contestsLoading && !selectedContest) || contests.length === 0) {
    return <div></div>;
  }

  const handleContestSelected = (selectedOptions: Option[]) => {
    setPhase(undefined);

    if (selectedOptions.length === 0) {
      setContest(undefined);
    } else {
      const contest = contests.find((c: Contest) => c.id === selectedOptions[0].id);
      setContest(contest);

      const phases = contest?.phases || [];
      const activePhase = phases.find(phase => phase.is_active);
      if (activePhase) {
        setPhase(activePhase);
      }
    }
  };

  const handlePhaseIdSelected = (phaseId: string | undefined) => {
    if (!phaseId) {
      setPhase(undefined);
    } else {
      if (selectedContest && selectedContest.phases) {
        setPhase((selectedContest.phases).find((p) => p.id === phaseId));
      } else {
        console.error("failed to find selected phase in the contest", phaseId);
      }
    }
  };

  const contestToOption = (contest: Contest) => ({ id: contest.id, label: contest.name } as Option);

  return (
    <div style={{ width: isMobile ? "100%" : "50%", paddingRight: 5, paddingBottom: 5 }}>
      <Typeahead
        id="leaderboard-event-filter"
        options={contests.map(c => contestToOption(c))}
        placeholder="Contest"
        disabled={disabled}
        clearButton
        onChange={(contestOptions) => handleContestSelected(contestOptions)}
        selected={selectedContest ? [contestToOption(selectedContest)] : []}
        filterBy={() => true}
      />
      {selectedContest && selectedContest.phases?.length &&
        <div style={{ marginTop: 5, display: "flex", flexDirection: "column" }}>
          <Form>
            <Form.Check
              style={{ display: "inline-flex" }}
              inline
              type="radio"
              label={<BlockyBoldText>Overall1</BlockyBoldText>}
              name="phases"
              disabled={disabled}
              checked={!selectedPhase}
              id="overall-phase"
              onChange={() => handlePhaseIdSelected(undefined)}
            />
            {(selectedContest.phases ?? []).map((phase: Phase) =>
              <Form.Check
                style={{ display: "inline-flex" }}
                key={phase.id}
                inline
                type="radio"
                id={phase.id}
                label={<BlockyBoldText>{phase.name}</BlockyBoldText>}
                name="phases"
                disabled={disabled}
                checked={selectedPhase?.id === phase.id}
                onChange={(e) => handlePhaseIdSelected(e.target.id)}
              />)}
          </Form>
        </div>}
    </div>
  );
};

export default ContestAndPhaseSelector;
