/**
 * @fileoverview SaveAvatarButton component is used to save avatar and badges selected in edit modal to the user's profile.
 */

import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { Button } from "react-bootstrap";
import React from "react";
import { useAppSelector } from "redux/hooks";
import { useDispatch } from "react-redux";
import { RewardEnum, secondaryColor } from "utils/constants";
import { getSubmitAvaterPath } from "utils/backend-path-builders";
import { postRequest } from "utils/httpClient";
import {
  setSelectedCategory,
  setShowModal,
  getAvatarModalState
} from "redux/slices/avatar-modal-slice";
import { setAvatar as setUserAvatar, setBadges } from "redux/slices/user-slice";

/**
 * Submits the selected avatar and badges to the backend.
 * @param avatar avatar url
 * @param badges list of badge urls
 */
const submitAvatar = (avatar: string, badges: string[]) => {
  const path = getSubmitAvaterPath();
  const body = {
    body : {
      avatar: avatar,
      badges: badges,
    }
  };
  try {
    postRequest(path, body);
  } catch (error) {
    console.error("Error when uploading avatar", error);
  }
}

const SaveAvatarButton: React.FC = () => {
  const dispatch = useDispatch();
  const avatarModalState = useAppSelector(getAvatarModalState);

  const handleSaveAvatar = () => {
    const selectedAvatar = avatarModalState.selectedAvatar;
    const selectedBadges = avatarModalState.selectedBadges;

    dispatch(setUserAvatar(selectedAvatar));
    dispatch(setBadges(selectedBadges));

    submitAvatar(selectedAvatar, selectedBadges);

    dispatch(setSelectedCategory(RewardEnum.AVATAR))
    dispatch(setShowModal(false));
  }

  return (
    <Button
      style={{
        backgroundColor: secondaryColor,
        border: "1px var(--secondary-color)",
        color: "black",
        borderRadius: "unset",
        height: 40,
        width: 150,
      }}
      onClick={handleSaveAvatar}
    >
      <BlockyBoldText>Save Avatar</BlockyBoldText>
    </Button>
  )
}

export default SaveAvatarButton;