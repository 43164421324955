import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import BlockyLightText from "components/custom-texts/blocky-light-text";

const PoweredBySparket = () => {
  return (
    <div
      style={{
        textAlign: "left",
        color: "white",
        marginBottom: 7,
        alignItems: "baseline",
      }}
    >
      <BlockyLightText
        style={{
          fontSize: 12,
          display: "flex",
          textAlign: "bottom",
          alignItems: "baseline",
        }}
      >
        Powered By
        <BlockyHeavyText style={{ color: "var(--sparket-gold)", fontSize: 13 }}>
          &nbsp;SPARKET
        </BlockyHeavyText>
      </BlockyLightText>
    </div>
  );
};

export default PoweredBySparket;
