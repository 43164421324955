/**
 * @fileoverview Profile header component for the profile section of the avatar modal.
 */
import styles from "./styles.module.css";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import React from "react";

const ProfileHeader : React.FC = () => {


  return (
    <div className={styles.text}>
      <BlockyBoldText>
        Profile
      </BlockyBoldText>
    </div>
  )
}


export default ProfileHeader