import BlockyMediumText from "components/custom-texts/blocky-medium-text";
import { Checkmark } from "react-checkmark";
import styles from "../badge-styles.module.css";
import { secondaryColor } from "utils/constants";

const AllowedBadgeContent = () => {
  return (
    <div className={styles.badgeContentWrapper}>
      <div>
        <Checkmark size="medium" color={secondaryColor} />
      </div>
      <div className={styles.iconSpacing}></div>
      <BlockyMediumText>Location verified</BlockyMediumText>
    </div>
  );
};

export default AllowedBadgeContent;
