import { useEffect } from "react";
import { ACCOUNT_NOT_CONFIRMED } from "hooks/auth";
import { Link } from "react-router-dom";
import { Button, Form } from "react-bootstrap";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { toast } from "react-toastify";
import OpenSansText from "components/custom-texts/open-sans-text";
import styles from "./styles.module.css";
import { Mixpanel } from "hooks/mixPanel";
import { close, showSignUp } from "redux/slices/login-modals-slice";
import LegalDisclaimer from "components/sign-up/legal-disclaimer";
import useVerificationCode from "./use-login-data";
import { LoginContentProps } from "components/sign-up/form";
import SixDigitCodeInput from "components/six-digit-code-input";
import { useAppSelector } from "redux/hooks";
import { getGroupState } from "redux/slices/group-slice";
import { secondaryColor } from "utils/constants";
import GoogleGLogo from "icons/google-G-logo.svg";
import FacebookLogo from "icons/facebook-logo.svg";

const SignInContent = ({
  setAllowClickOutside,
  showConfirmationForm,
  setShowConfirmationForm,
}: LoginContentProps) => {
  useEffect(() => {
    if (showConfirmationForm) {
      setAllowClickOutside(false);
    } else {
      setAllowClickOutside(true);
    }
  }, [showConfirmationForm]);

  const {
    code,
    setCode,
    wrongCode,
    setWrongCode,
    loading,
    email,
    onChangeEmail,
    password,
    onChangePassword,
    auth,
    dispatch,
  } = useVerificationCode();

  const group = useAppSelector(getGroupState);
  const iframe = group.customization.iframe;
  const groupId = group.id;

  if (showConfirmationForm) {
    return (
      <div
        style={{
          color: "white",
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
          justifyContent: "space-evenly",
          paddingBottom: 40,
          height: 200,
        }}
      >
        <OpenSansText style={{ textAlign: "center" }}>
          Your account has not been verified. We sent a code to {email}.
        </OpenSansText>
        <OpenSansText>Please enter the code below.</OpenSansText>

        <SixDigitCodeInput
          {...{ code, wrongCode, setWrongCode, setCode, loading }}
        />
      </div>
    );
  }

  return (
    <Form
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        maxWidth: 330,
      }}
    >
      {/* google */}
      {!iframe && (
        <>
          <Button
            style={{
              marginBottom: "2vh",
              width: "100%",
              backgroundColor: "#FFFFFF",
              textAlign: "left",
            }}
            onClick={() => {
              Mixpanel.track("Clicked Sign In using Google", {
                isSignedIn: auth.signedIn,
              });
              auth.googleSignIn(groupId).catch((err) => {
                Mixpanel.track("Error Signing in with Google", {
                  isSignedIn: auth.signedIn,
                });
                toast.error("Error signing in with Google");
              });
            }}
          >
            <img width="20px" alt="Google sign-in" src={GoogleGLogo} />
            <OpenSansText className={styles.googleText}>
              Continue with Google
            </OpenSansText>
          </Button>
          <Button
            style={{
              marginBottom: "2vh",
              width: "100%",
              backgroundColor: "#4267b2",
              textAlign: "left",
            }}
            onClick={() => {
              Mixpanel.track("Clicked Sign In through Facebook", {
                isSignedIn: auth.signedIn,
              });
              auth.facebookSignIn(groupId).catch((err) => {
                Mixpanel.track("Error Signing in with Facebook", {
                  isSignedIn: auth.signedIn,
                });
                toast.error("Error signing in with Facebook");
              });
            }}
          >
            <img width="20px" alt="Facebook sign-in" src={FacebookLogo} />
            {/* facebook */}
            <OpenSansText className={styles.facebookText}>
              Continue with Facebook
            </OpenSansText>
          </Button>
          <OpenSansText style={{ color: "#FFFFFF", marginBottom: "1vh" }}>
            - OR -
          </OpenSansText>
        </>
      )}
      <Form.Group style={{ width: "inherit" }}>
        <Form.Control
          style={{ marginBottom: "1vh" }}
          type="email"
          placeholder="Email"
          onChange={(e) => onChangeEmail(e.target.value)}
        />
      </Form.Group>
      <Form.Group style={{ width: "inherit" }}>
        <Form.Control
          style={{ marginBottom: "1vh" }}
          type="password"
          placeholder="Password"
          onChange={(e) => onChangePassword(e.target.value)}
        />
      </Form.Group>
      <Button
        onClick={async () => {
          Mixpanel.track("Clicked Sign In using Username and Password", {
            isSignedIn: auth.signedIn,
          });
          const result = await auth.signIn({ username: email, password });
          if (result === ACCOUNT_NOT_CONFIRMED) {
            await auth.resendSignUp(email);
            setShowConfirmationForm(true);
          }
        }}
        style={{
          marginBottom: "2vh",
          width: "100%",
          backgroundColor: secondaryColor,
          color: "black",
        }}
      >
        <BlockyBoldText className={styles.signedInBtnText}>
          Sign In
        </BlockyBoldText>
      </Button>
      <div style={{ display: "flex", marginBottom: "1vh" }}>
        <Link
          to="/forgot-password"
          onClick={() => {
            Mixpanel.track("Clicked Forgot Password on the sign in page", {
              isSignedIn: auth.signedIn,
            });
            dispatch(close());
          }}
        >
          <BlockyBoldText style={{ color: secondaryColor}}>
            Forgot password?
          </BlockyBoldText>
        </Link>
      </div>
      <div style={{ display: "flex", marginBottom: "2vh" }}>
        <BlockyBoldText
          style={{ color: "#FFFFFF", marginRight: 5, fontSize: "1.2em" }}
        >
          Need an account?
        </BlockyBoldText>
        <Link
          to="#"
          onClick={() => {
            dispatch(showSignUp());
            Mixpanel.track("Clicked Sign Up from the Sign In page", {
              isSignedIn: auth.signedIn,
            });
          }}
        >
          <BlockyBoldText style={{ color: secondaryColor, fontSize: "1.2em" }}>
            SIGN UP
          </BlockyBoldText>
        </Link>
      </div>
      <LegalDisclaimer />
    </Form>
  );
};

export default SignInContent;
