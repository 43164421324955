import sparketTutorial1 from "assets/sparket-tutorial/sparket-tutorial-1.png";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import BlockyBlockyMediumText from "components/custom-texts/blocky-medium-text";
import styles from "./styles.module.css";

const PageOneContent = () => {
  return (
    <div className={styles.pageContentContainer}>
      <BlockyHeavyText className={styles.topText}>
        This app hosts prediction pools with other real players.
      </BlockyHeavyText>
      <img className="d-block w-100" src={sparketTutorial1} alt="First slide" />
      <BlockyBlockyMediumText className={styles.bottomText}>
        In prediction pools, instead of picking against the house, the odds
        are set <span className={styles.bold}>based on the actual volume</span>{" "}
        of picks on each contestant.
      </BlockyBlockyMediumText>
    </div>
  );
};

export default PageOneContent;
