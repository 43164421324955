import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import UserGroupsResponse from "interfaces/user-groups-response";
import { getAvailableGroupsPath, getGroupDetailsPath } from "utils/backend-path-builders";
import { FAILED, IS_INTEGRATED_APP, LOADING, SUCCEEDED } from "utils/constants";
import { getRequest, isIntegratedApp } from "utils/httpClient";
import { RootState } from "../store";
import * as storage from "utils/local-storage";

interface AvailableGroupsState {
  groups: UserGroupsResponse[];
  status: "idle" | "loading" | "succeeded" | "failed";
  error: string | any;
}

export const initialState: AvailableGroupsState = {
  groups: [],
  status: "idle",
  error: null,
};

export const fetchAvailableGroups = createAsyncThunk(
  "availableGroups/fetchOptions",
  async () => {
    if (isIntegratedApp()) {
      return;
    }
    const response = await getRequest(getAvailableGroupsPath());
    return response || [];
  }
);

const slice = createSlice({
  name: "availableGroups",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder.addCase(fetchAvailableGroups.pending, (state, _) => {
      state.status = LOADING;
    });
    builder.addCase(fetchAvailableGroups.fulfilled, (state, action) => {
      state.status = SUCCEEDED;
      state.groups = action.payload;
    });
    builder.addCase(fetchAvailableGroups.rejected, (state, action) => {
      state.status = FAILED;
      state.error = action.error.message;
    });
  },
});

export const getAvailableGroups = (state: RootState) => {
  return state.availableGroups.groups;
};

export const getAvailableGroupsStatus = (state: RootState) => {
  return state.availableGroups.status;
};

export default slice.reducer;
