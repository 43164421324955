import { useState, useEffect } from "react";
import { useAuth } from "hooks/auth";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { close } from "redux/slices/login-modals-slice";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import SixDigitCodeInput from "components/six-digit-code-input";
import { secondaryColor } from "utils/constants";

const ConfirmMfaContent = () => {
  const [code, setCode] = useState("");
  const auth = useAuth();
  const phoneNumber = auth.user.challengeParam?.CODE_DELIVERY_DESTINATION || "";
  const [wrongCode, setWrongCode] = useState(false);
  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function confirmAuth() {
      if (code.length === 6) {
        setLoading(true);
        try {
          await auth.confirmSmsMfaSignIn(code);
          dispatch(close());
        } catch (error) {
          if ((error as any).name === "CodeMismatchException") {
            setWrongCode(true);
            setCode("");
          }
        } finally {
          setLoading(false);
        }
      }
    }

    confirmAuth();
  }, [code]);

  return (
    <>
      <div></div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <BlockyHeavyText style={{ color: secondaryColor, fontSize: 20 }}>
          Enter Code
        </BlockyHeavyText>
        {phoneNumber && (
          <BlockyHeavyText style={{ color: "white", fontSize: 12 }}>
            We sent a code to {phoneNumber}
          </BlockyHeavyText>
        )}

        <SixDigitCodeInput
          {...{ code, wrongCode, setWrongCode, setCode, loading }}
        />
      </div>
      <div></div>
    </>
  );
};

export default ConfirmMfaContent;
