import { getRequest } from "utils/httpClient";
import { Button } from "react-bootstrap";
import { LeaderboardResponse } from "interfaces/leaderboard/leaderboard-response";
import React, { useEffect, useState } from "react";
import LoadingSpinner from "components/loading-spinner.tsx";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import styles from "./styles.module.css";
import BlockyBoldText from "components/custom-texts/blocky-bold-text";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getGroupState, getGroupStyles, setSelectedContest } from "redux/slices/group-slice";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Fullscreen } from "react-bootstrap-icons";
import FullscreenHeader from "./full-screen-header";
import useDeviceDimensions from "hooks/useDeviceDimensions";
import LeaderTable from "./leader-table";
import CallToActionFooter from "./full-screen-footer/call-to-action-footer";
import { openContestTerms } from "redux/slices/legal-modal-slice";
import ContestAndPhaseSelector from "./contest-filter";
import { Contest } from "interfaces/leaderboard/contest";
import { getLeaderboardDataPath } from "utils/backend-path-builders";
import { Phase } from "interfaces/leaderboard/phase";
import { textColor } from "utils/constants";
import { Mixpanel } from "hooks/mixPanel";


export interface Option {
  id: string;
  label: string;
}

const Leaderboard = () => {

  const dispatch = useAppDispatch();

  const group = useAppSelector(getGroupState);
  const groupId = group.id;

  const primaryColor = useAppSelector(getGroupStyles).primary_color;

  const [contestsLoaded, setContestsLoaded] = useState(false);

  const [contestFilter, setContestFilter] = useState<Contest>();
  const [phaseFilter, setPhaseFilter] = useState<Phase>();

  const [loadingLeaderboard, setLoadingLeaderboard] = useState(false);
  const [leaderboardData, setLeaderboardData] = useState<LeaderboardResponse>();

  const handle = useFullScreenHandle();
  const fullScreenActive = handle.active;
  const enterFullScreen = handle.enter;
  const { isMobile } = useDeviceDimensions();
  const [event, setEvent] = useState<any>();


  const handleSetContest = (contest: Contest | undefined) => {
    setContestFilter(contest);
    dispatch(setSelectedContest(contest));
  };

  useEffect(() => {
    Mixpanel.track("Leaderboard rendered", {});
  });

  useEffect(() => {
    if (!groupId || !contestsLoaded) {
      return;
    }
    setLoadingLeaderboard(true);
    getRequest(getLeaderboardDataPath(), {
      skipIntegrationApi: true,
      queryParams: {
        currency: group.currency_code || "skc",
        page_size: 100,
        "group-id": groupId,
        ...(event?.id && { "event-id": event?.id }),
        ...(contestFilter?.id && { "contest-id": contestFilter?.id }),
        ...(phaseFilter?.id && { "phase-id": phaseFilter?.id }),
      },
    })
      .then((response: LeaderboardResponse) => {
        setLeaderboardData(response);
        setLoadingLeaderboard(false);
      })
      .catch((e) => {
        console.error("failed to load leaderboard", e);
        setLoadingLeaderboard(false);
      });
  }, [groupId, event?.id, contestFilter?.id, phaseFilter?.id, contestsLoaded]);

  return (
    <FullScreen handle={handle} className={styles.fullScreen}>
      {fullScreenActive && <FullscreenHeader />}
      <>
        <div>
          <div style={{ backgroundColor: primaryColor }}>
            <div className={styles.titleRow}>
              <div style={{ display: "flex", flexWrap: "wrap" }}>
                <BlockyHeavyText
                  customStyles={{
                    fontSize: 22,
                    display: "flex",
                    alignItems: "center",
                    color: textColor,
                  }}
                >
                  Leaderboard
                </BlockyHeavyText>
                {fullScreenActive &&
                  [event?.label, contestFilter?.name, phaseFilter?.name]
                    .filter((string) => string !== undefined)
                    .map((filterLabel) => {
                      return (
                        <BlockyHeavyText
                          key={filterLabel}
                          customStyles={{
                            fontSize: 22,
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {" "}
                          &nbsp;-&nbsp;{filterLabel}
                        </BlockyHeavyText>
                      );
                    })}
              </div>

              {!fullScreenActive && (
                <div style={{ display: "flex" }}>
                  {!isMobile && (
                    <Button
                      style={{
                        backgroundColor: "var(--sparket-black)",
                        border: "none",
                        color: "white",
                      }}
                      onClick={enterFullScreen}
                    >
                      <Fullscreen size={12} /> Fullscreen
                    </Button>
                  )}
                  {contestFilter && (
                    <>
                      <div style={{ marginLeft: 5 }} />
                      <Button
                        style={{
                          backgroundColor: "var(--sparket-black)",
                          border: "none",
                          color: "white",
                        }}
                        onClick={() => dispatch(openContestTerms())}
                      >
                        View Contest Terms
                      </Button>
                    </>
                  )}
                </div>
              )}
            </div>
            <div style={{ display: fullScreenActive ? "none" : "initial" }}>
              <hr style={{ marginTop: 0, marginBottom: 0 }} />
              <div
                style={{ backgroundColor: primaryColor }}
                className={styles.filterRow}
              >
                <ContestAndPhaseSelector
                  selectedContest={contestFilter}
                  setContest={contest => handleSetContest(contest)}
                  selectedPhase={phaseFilter}
                  setPhase={setPhaseFilter}
                  disabled={loadingLeaderboard}
                  onContestLoaded={() => setContestsLoaded(true)}
                />
              </div>
            </div>
          </div>
          <>
            {!contestsLoaded || loadingLeaderboard || !leaderboardData ?
              <LoadingSpinner /> :
              <>
                {leaderboardData.user_list ?
                  <>
                    <LeaderTable
                      fullScreen={fullScreenActive}
                      leaderboardUser={leaderboardData.user_entry}
                      leaderboardList={leaderboardData.user_list}
                    />
                    <div className={styles.totalContestants}>
                      <BlockyBoldText>Out of </BlockyBoldText>
                      <BlockyHeavyText
                        style={{
                          marginLeft: 3,
                          marginRight: 3,
                          color: primaryColor,
                        }}
                      >
                        {leaderboardData.total_contestants}
                      </BlockyHeavyText>
                      <BlockyBoldText> contestants</BlockyBoldText>
                    </div>
                  </> :
                  <BlockyHeavyText
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: 15,
                    }}
                  >
                    No results yet, please check back later.
                  </BlockyHeavyText>}
              </>}
          </>
        </div>
      </>
      {fullScreenActive && <CallToActionFooter />}
    </FullScreen>
  );
};

export default Leaderboard;
