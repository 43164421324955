export enum IDENTITY_VERIFICATION_STATUS {
  NOT_STARTED = "",
  PENDING = "PENDING",
  APPROVED = "APPROVED",
  REJECTED = "REJECTED",
  DUPLICATED = "DUPLICATED",
  MINIMAL_AGE = "MINIMAL_AGE",
}

export default interface UsersResponse {
  avatar: string;
  badges: string[];
  bio: string;
  created_at: string;
  id: string;
  last_login: string;
  last_seen: string;
  password_last_changed: string;
  status: string;
  super_admin: false;
  user_name: string;
  validation_status: IDENTITY_VERIFICATION_STATUS;
  validation_request_id: string;
  notifications: { email: object };
}
