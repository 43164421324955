import { useEffect, useState } from "react";
import EntryList from "components/pool-details/entries/entry-list";
import { Entry } from "interfaces/entry";
import { PoolDetailsResponse } from "interfaces/pool-details-response";
import { getRequest } from "utils/httpClient";
import Header from "components/header";
import Headroom from "react-headroom";
import LoadingSpinner from "components/loading-spinner.tsx";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getGroupState, setGroup } from "redux/slices/group-slice";
import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import LoginButtons from "components/login-buttons";
import { getGroupDetailsPath, getPoolDetailsPath, } from "utils/backend-path-builders";
import useQueryParams from "utils/useQueryParams";
import { local as localStorage } from "utils/local-storage";
import SuccessfulBetModal from "components/pool-details/successful-bet-modal";
import { Bet } from "interfaces/bet";
import PoolSummary from "./pool-summary";
import { BetTypeName } from "interfaces/bet-type-name";
import { ContestantResponse } from "interfaces/contestant-response";
import { sortBetTypes } from "components/pool-details/bet-types";
import { findFirst } from "utils/array-utils";

const PoolDetails = (props: any) => {
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(true);
  const [entries, setEntries] = useState<Entry[]>([]);
  const [contestants, setContestants] = useState<ContestantResponse[]>([]);
  const [pool, setPool] = useState<PoolDetailsResponse>(
    {} as PoolDetailsResponse
  );
  const group = useAppSelector(getGroupState);
  const [successfulBet, setSuccessfulBet] = useState<Bet | undefined>();
  const [currentPoolType, setCurrentPoolType] = useState<BetTypeName>();

  const onSuccessfulBetPlaced = (bet: Bet) => {
    setSuccessfulBet(bet);
    setLoading(true);
  };

  useEffect(() => {
    const id: number = props.match.params.id;
    const path = getPoolDetailsPath(group.id, id.toString());
    getRequest(path, { skipIntegrationApi: true }).then((pool: PoolDetailsResponse) => {
      setEntries(pool.entries);
      setContestants(pool.contestants);
      setCurrentPoolType(findFirst(sortBetTypes(pool.pool_types)));
      setPool(pool);

      if (pool.group_id && pool.group_id !== group.id) {
        const path = getGroupDetailsPath(pool.group_id);
        getRequest(path, { skipIntegrationApi: true }).then((data) => {
          dispatch(setGroup(data));
        });
      }

      setLoading(false);

    });
  }, [props.match.params.id, loading]);

  const queryParams = useQueryParams();
  const referrerID = queryParams.get("utm_source");
  const referrerGroupID = queryParams.get("utm_campaign");
  const referrerMedium = queryParams.get("utm_medium");

  if (referrerGroupID != null) {
    if (referrerID != null) {
      localStorage.setItem("referrer-id-" + referrerGroupID, `${referrerID}`);
    }
    if (referrerMedium != null) {
      localStorage.setItem(
        "referrer-medium-" + referrerGroupID,
        `${referrerMedium}`
      );
    }
  }

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
      <Headroom>
        <Header />
        <PoolSummary pool={pool} betType={currentPoolType} />
        <LoginButtons />
      </Headroom>

      {(contestants && contestants.length >= 1) ||
      (entries && entries.length >= 1) ?
        <EntryList
          pool={pool}
          setCurrentPoolType={setCurrentPoolType}
          onSuccessfulBetPlaced={onSuccessfulBetPlaced}
        /> :
        <BlockyHeavyText
          style={{
            color: "white",
            display: "flex",
            justifyContent: "center",
            marginTop: 15,
          }}
        >
          This pool has no contestants
        </BlockyHeavyText>
      }
      {successfulBet && (
        <SuccessfulBetModal
          bet={successfulBet}
          close={() => setSuccessfulBet(undefined)}
        />
      )}
    </>
  );

};

export default PoolDetails;
