import BlockyHeavyText from "components/custom-texts/blocky-heavy-text";
import { Button, Fade } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "redux/hooks";
import { getGroupState, requestToJoinGroup } from "redux/slices/group-slice";
import { useEffect, useState } from "react";
import Spinner from "react-bootstrap/Spinner";
import { Checkmark } from "react-checkmark";
import styles from "./styles.module.css";
import {
  APPROVED,
  NOT,
  PENDING,
  PENDING_IDENTITY_VERIFICATION,
  secondaryColor,
} from "utils/constants";
import { fetchAvailableGroups } from "redux/slices/available-groups-slice";
import { getRequest } from "utils/httpClient";
import JoinGroupModal from "./join-group-modal";
import { openExternalIdModal } from "redux/slices/external-id-modal-slice";
import { getJoinGroupPath } from "utils/backend-path-builders";
import PendingIdentityVerificationTooltip from "./pending-identity-verification-tooltip";
import { local as localStorage } from "utils/local-storage";
import {
  blockContestModal,
  openContestPopup,
} from "redux/slices/contest-popup-modal-slice";
import { SEEN_CONTEST_POPUP_URLS } from "components/contest-popup/modal";
import { getIntegratedAppState } from "redux/slices/integrated-app-slice";

interface ButtonTextProps {
  pending: boolean;
  pendingIdentityVerification: boolean;
}

const ButtonText = ({
  pending,
  pendingIdentityVerification,
}: ButtonTextProps) => {
  let text = "Join Group";

  if (pending) {
    text = "Pending";
  }

  if (pendingIdentityVerification) {
    text = "Pending Identity Verification";
  }
  return (
    <BlockyHeavyText
      style={{
        fontStyle:
          pending || pendingIdentityVerification ? "italic" : "initial",
        color: "black"
      }}
    >
      &nbsp;&nbsp; {text} &nbsp;&nbsp;
      {pendingIdentityVerification && <PendingIdentityVerificationTooltip />}
    </BlockyHeavyText>
  );
};

const Container = () => {
  const dispatch = useAppDispatch();
  const group = useAppSelector(getGroupState);
  const { enable_contest_popup, contest_popup_url } = group.settings;
  const haventSeenContestYet =
    contest_popup_url &&
    !localStorage.getItem(SEEN_CONTEST_POPUP_URLS)?.includes(contest_popup_url);
  const [loading, setLoading] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const [showWelcomeMessage, setShowWelcomeMessage] = useState(false);
  const [pending, setPending] = useState(false);
  const [
    pendingIdentityVerification,
    setPendingIdentityVerification,
  ] = useState(false);
  const [approved, setApproved] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const isIntegration = useAppSelector(getIntegratedAppState).isIntegratedApp;

  useEffect(() => {
    if (group.settings.external_id_label && !approved) {
      // this will prevent the contest modal from appearing if the
      // external id modal is about to appear also.
      dispatch(blockContestModal(true));
    }
  }, [group.settings.external_id_label]);

  // when group is changed fetch group status
  useEffect(() => {
    if (isIntegration) {
      setShowButton(false);
      return;
    }
    setLoading(true);
    const path = getJoinGroupPath(group.id);
    getRequest(path, {
      skipIntegrationApi: true,
      responseType: "text",
      queryParams: {
        "referrer-id": localStorage.getItem("referrer-id-" + group.id) || "",
        medium: localStorage.getItem("referrer-medium-" + group.id) || "",
      },
    }).then((groupMembershipStatus) => {
      if (groupMembershipStatus === PENDING) {
        setShowButton(true);
        setApproved(false);
        setPending(true);
        setLoading(false);
      } else if (groupMembershipStatus === PENDING_IDENTITY_VERIFICATION) {
        setShowButton(true);
        setApproved(false);
        setPendingIdentityVerification(true);
        setLoading(false);
      } else if (groupMembershipStatus === NOT) {
        setShowButton(true);
        setApproved(false);
        setPending(false);
        setLoading(false);
      } else if (groupMembershipStatus === APPROVED) {
        setShowButton(false);
      }
    });
  }, [group.id]);

  useEffect(() => {
    setLoading(false);

    if (group.status === APPROVED) {
      setApproved(true);
    }

    if (group.status === PENDING) {
      setPending(true);
    }
  }, [group.status]);

  useEffect(() => {
    if (approved && showButton) {
      dispatch(fetchAvailableGroups());
      setTimeout(() => {
        setShowWelcomeMessage(true);
        if (group.settings.external_id_label) {
          setTimeout(() => {
            setShowButton(false);
            setShowWelcomeMessage(false);
            dispatch(openExternalIdModal());
            dispatch(blockContestModal(false));
          }, 3000);
        } else {
          if (enable_contest_popup && haventSeenContestYet) {
            dispatch(openContestPopup());
          }
        }
      }, 3000);
    }
  }, [approved]);

  if (showWelcomeMessage) {
    return (
      <div
        style={{
          color: "white",
          backgroundColor: "var(--sparket-dark-green)",
          borderRadius: 20,
          height: 31,
          paddingLeft: 10,
          paddingRight: 10,
          display: "flex",
          alignItems: "center",
        }}
      >
        <BlockyHeavyText>Welcome to {group.name}</BlockyHeavyText>
      </div>
    );
  }

  return (
    <>
      <Fade in={showButton} unmountOnExit>
        <div>
          <Button
            className={styles.button}
            disabled={pending}
            onClick={() => {
              if (!pendingIdentityVerification) {
                setShowModal(true);
              }
            }}
          >
            <>
              <div style={{ display: "flex", justifyContent: "center" }}>
                {loading ? (
                  <Spinner
                    animation="border"
                    style={{ height: 25, width: 25 }}
                  />
                ) : (
                  <>
                    {approved ? (
                      <Checkmark size="medium" color="black" />
                    ) : (
                      <ButtonText
                        pending={pending}
                        pendingIdentityVerification={
                          pendingIdentityVerification
                        }
                      />
                    )}
                  </>
                )}
              </div>
            </>
          </Button>
        </div>
      </Fade>
      <JoinGroupModal
        acceptAndJoin={() => {
          setLoading(true);
          dispatch(requestToJoinGroup(group.id));
          setShowModal(false);
        }}
        show={showModal}
        close={() => setShowModal(false)}
      />
    </>
  );
};

export default Container;
