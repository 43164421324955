import BlockyMediumText from "components/custom-texts/blocky-medium-text";
import { Spinner } from "react-bootstrap";
import styles from "../badge-styles.module.css";
import { secondaryColor } from "utils/constants";

const WaitingBadgeContent = () => {
  return (
    <div className={styles.badgeContentWrapper}>
      <Spinner size="sm" animation="grow" style={{ color: secondaryColor}} />
      <div className={styles.iconSpacing}></div>
      <BlockyMediumText>Verifying your location</BlockyMediumText>
    </div>
  );
};

export default WaitingBadgeContent;
